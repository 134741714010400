import React from "react";
import { NumberFormatter } from "../../../../../../../utils/validator";
import { useStateGlobalContext } from "../../../../../../../contexts/GlobalContext";
import { Card, Divider } from "antd";
import LabelDisplay from "../../../../../../../components/LabelDisplay/LabelDisplay";

const InvoiceFooter = ({
  className = "text-right float-right",
  subTotal,
  discount,
  discountAmount,
  tax,
  grandTotal,
}) => {
  const { officeSetting } = useStateGlobalContext();

  return (
    <Card className="mt-2 rounded-lg"
    >
      <div className="flex justify-between border-b">
        <div className="text-slate-1000">Sub Total:</div>
        <div className="text-slate-1000"><NumberFormatter value={subTotal} /></div>
      </div>
      <Divider className="m-2"/>

      <div className="flex justify-between  border-b">
        <div className="text-slate-1000">Discount {discount}%:</div>
        <div className="text-slate-1000"><NumberFormatter value={discountAmount} /></div>
      </div>
      <Divider  className="m-2"/>

      <div className="flex justify-between border-b">
        <div className="text-slate-1000">Tax {officeSetting?.local_tax_rate}%: </div>
        <div className="text-slate-1000"><NumberFormatter value={tax} /></div>
      </div>

      <Divider  className="m-2"/>
      

      <div className="flex justify-between border-b">
        <div className="text-slate-1000 mr-2">Grand Total: </div>
        <div className="text-slate-1000"><NumberFormatter value={grandTotal} /></div>
      </div>

    </Card>
  );
};

export default InvoiceFooter;
