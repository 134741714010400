import React from "react";
import { Button, Card } from "antd";
import { usePDF } from "react-to-pdf";
import ClinicInformation from "../../../../../components/PrescriptionsPad/ClinicInformation";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";
import ViewTemplate from "../../../Templates/components/ViewTemplate";
import PatientInformation from "../../../../../components/PrescriptionsPad/PatientInformation";
import PrintDrawer from "../../../../../components/GlobalComponents/PrintDrawer";

const LabResultsPrescription = ({ patient, visible, onClose, data }) => {

  const { officeSetting } = useStateGlobalContext();

  const { toPDF, targetRef } = usePDF({
    filename: `${data?.lab_order_id}.pdf` || "Invoice.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };

  return (
    <PrintDrawer title={data?.lab_order_id} visible={visible} onClose={onClose}>
      <div className="invoice-print">
        <div className="invoice-container">
          <Button onClick={downloadPDF} className="mb-2" type="primary">
            Download PDF
          </Button>
          <Card ref={targetRef}>
            <div className="flex justify-between">
            <div>
            <p className="font-semibold">Facility Information</p>
            {ClinicInformation(officeSetting, "text-left")}
            </div>
            <PatientInformation patient={patient}/>
            </div>
            <Card>
              <LabelDisplay title="Service Name" description={data?.service_name}/>
              <LabelDisplay
                className="flex-col items-start"
                title="Lab Results & Description"
                description={<ViewTemplate template={data?.description} />}
              />
            </Card>
          </Card>
        </div>
      </div>
    </PrintDrawer>
  );
};

export default LabResultsPrescription;
