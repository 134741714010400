import { Button, Card, Table } from "antd";
import React from "react";
import InvoiceHeader from "../../../../../../../../components/InvoiceHeader/InvoiceHeader";
import { useStateGlobalContext } from "../../../../../../../../contexts/GlobalContext";
import { NumberFormatter } from "../../../../../../../../utils/validator";
import InvoiceFooter from "../../../component/InvoiceFooter/InvoiceFooter";
import { calTotal } from "../../../../../../../../utils/validator";
import { usePDF } from "react-to-pdf";
import {
  fullName,
  InvoiceBar,
} from "../../../../../../../../components/GlobalComponents/GlobalComponents";
import PrintDrawer from "../../../../../../../../components/GlobalComponents/PrintDrawer";

const coloumn = [
  {
    key: 1,
    title: "#",
    render: (_, record, index) => index + 1,
  },
  {
    key: 2,
    title: "Lab Name",
    dataIndex: "lab_name",
  },
  {
    key: 3,
    title: "Price",
    align: "center",
    dataIndex: "lab_price",
    render: (text) => (
      <NumberFormatter value={text} options={{ style: "decimal" }} />
    ),
  },
];

const LabPad = ({ visible, onClose, rxData }) => {
  const { officeSetting, patientBasicInfo, billingInformation } =
    useStateGlobalContext();
  const subTotal = calTotal(rxData?.lab_name);

  const { toPDF, targetRef } = usePDF({
    filename: `${rxData?.inhouseUID}.pdf` || "Invoice.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };

  // const contentRef = useRef(null);
  // const handlePrint = useReactToPrint({
  //   content: () => contentRef.current,
  // });

  return (
    <PrintDrawer
      title={fullName(patientBasicInfo)}
      visible={visible}
      onClose={onClose}
    >
      <div className="invoices-print max-w-[900px]">
        <div className="invoice-container">
          <Button onClick={downloadPDF} className="mb-2" type="primary">
            Download PDF
          </Button>
          <Card className="invoice-content" ref={targetRef}>
            <InvoiceBar invoice={rxData} />
            <InvoiceHeader
              officeInformation={officeSetting}
              patientInformation={patientBasicInfo}
              rxData={rxData}
              billingInformation={billingInformation}
            />
            <Card>
              <Table
                columns={coloumn}
                size="small"
                dataSource={rxData?.lab_name}
                pagination={false}
              />
            </Card>

            <InvoiceFooter
              subTotal={subTotal}
              discount={rxData?.discount}
              discountAmount={rxData?.discounted_amount}
              tax={rxData?.tax}
              grandTotal={rxData?.grand_total}
            />
          </Card>
        </div>
      </div>
    </PrintDrawer>
  );
};

export default LabPad;
