import React, { useEffect } from "react";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import { Divider } from "antd";
import "tailwindcss/tailwind.css";
import FormatDate from "../FormatDate/FormatDate";
import LabelDisplay from "../LabelDisplay/LabelDisplay";
import { fullAddress, fullName } from "../GlobalComponents/GlobalComponents";

const renderInsuranceInfo = (insurance, type) => {
  if (!insurance || insurance.length === 0) {
    return (
      <>
        <h4 className="text-center mb-6 text-xl font-semibold">
          Patient's {type === "primary" ? "Primary" : "Secondary"} Insurance
        </h4>
        <p>No {type} insurance data available</p>
      </>
    );
  }

  const insuranceData = insurance[0];

  return (
    <>
      <h4 className="text-center mb-6 text-xl font-semibold">
        Patient's {type === "primary" ? "Primary" : "Secondary"} Insurance
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <LabelDisplay title="Type" description={insuranceData.insurance_type} />
        <LabelDisplay
          title="Company"
          description={insuranceData.insurance_company?.insurance_company_name}
        />
        <LabelDisplay
          title="Company Phone"
          description={insuranceData.insurance_company?.insurance_company_phone}
        />
        <LabelDisplay
          title="Plan"
          description={insuranceData.insured_person_info?.insurance_plan}
        />
        <LabelDisplay
          title="Group"
          description={insuranceData.insured_person_info?.insured_group}
        />
        <LabelDisplay
          title="Policy"
          description={insuranceData.insured_person_info?.insurance_policy}
        />
      </div>
    </>
  );
};

const FaceSheetHeader = ({ primaryInsurance, secondaryInsurance }) => {
  const { id } = useParams();
  const { patientBasicInfo: data, getPatientData } = useStateGlobalContext();

  useEffect(() => {
    getPatientData(id);
  }, [id]);
  // <p>Payment Method: {rxData?.payment_method ? rxData.payment_method.charAt(0).toUpperCase() + rxData.payment_method.slice(1) : ''}</p>

  return (
    <div className="p-4">
      <h4 className="text-center mb-6 text-xl font-semibold">
        Patient Demographics
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <LabelDisplay title="Full Name" description={fullName(data)} />
        <LabelDisplay
          title="Gender"
          description={
            data?.gender?.charAt(0).toUpperCase() + data?.gender?.slice(1)
          }
        />
        <LabelDisplay
          title="DOB"
          description={<FormatDate date={data?.date_of_birth} type="date" />}
        />
        <LabelDisplay
          title="Marital Status"
          description={data?.marital_status}
        />
        <LabelDisplay title="Phone Number" description={data?.patient_phone} />
        <LabelDisplay title="Email" description={data?.patient_email} />
        <LabelDisplay title="Full Address" description={fullAddress(data)} />
        <LabelDisplay
          title="Preferred Language"
          description={data?.languages}
        />
        <LabelDisplay title="Religion" description={data?.religion} />
        <LabelDisplay title="Social Security" description={data?.ssn} />
        <LabelDisplay title="Driver Lic" description={data?.license} />
      </div>

      <Divider className="my-6" />

      <div>
        <h4 className="text-center mb-6 text-xl font-semibold">
          Emergency Contact Information
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <LabelDisplay
            title="Full Name"
            description={`${data?.emergency_first_name}${" "}
            ${data?.emergency_last_name}`}
          />
          <LabelDisplay
            title="Phone"
            description={data?.emergency_phone_number}
          />
          <LabelDisplay
            title="Relation"
            description={data?.emergency_relationship}
          />
        </div>
      </div>

      <Divider className="my-6" />

      <div>
        {primaryInsurance && renderInsuranceInfo(primaryInsurance, "primary")}
        {primaryInsurance && <Divider className="my-6" />}
        {secondaryInsurance &&
          renderInsuranceInfo(secondaryInsurance, "secondary")}
      </div>
    </div>
  );
};

export default FaceSheetHeader;
