import React from "react";
import { Table, Modal, Button} from "antd";
import { NumberFormatter } from "../../../../../../../utils/validator";
import FormatDateTime from "../../../../../../../components/FormatDate/FormatDateTime";
import { CopyableText } from "../../../../../../../components/GlobalComponents/GlobalComponents";
import LabelDisplay from "../../../../../../../components/LabelDisplay/LabelDisplay";

const ProductsDrawer = ({ visible, data, onClose }) => {


  const coloumn = [
    {
      key: 1,
      title: "#",
      render: (_, record, index) => index + 1,
    },
    {
      key: 2,
      title: "Product Name", 
      dataIndex: 'items',
    },
    {
        key: 3,
        title: "Unit Price",
        dataIndex: 'unit_price',
      },
    {
      key: 4,
      title: "Quantity",
      dataIndex: 'quantity',
    },
    {
        key: 5,
        title: "Price",
        dataIndex: 'price',
        render:(text)=> <NumberFormatter value={text}/>
      },
  ];


  return (
    <Modal
      title={<CopyableText text={data?.invoice_id}/>}
      destroyOnClose
      placement="right"
      width="780px"
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >


      <LabelDisplay title="Patient Name" description={data?.patient_name}/>
      <LabelDisplay title="Date Added" description={<FormatDateTime date={data?.issue_date} type="datetime"/>}/>
      <LabelDisplay title="Created By" description={data?.added_by}/>
      <LabelDisplay title="Notes" description={data?.notes}/>

      <Table
        className="mt-4"
        columns={coloumn}
        rowKey={(record) => record?.id}
        dataSource={data?.product_details}
        size="small"
        pagination={false}
      />

      <div className="mt-4">
      <LabelDisplay title="Total" description={<NumberFormatter value={data?.price} options={{ style: "decimal" }} />}/>
      <LabelDisplay title="Discount" description={`${data?.discount} %`}/>
      <LabelDisplay title="Tax" description={`${data?.tax} %`}/>
      <LabelDisplay title="Grand Total" description={<NumberFormatter value={data?.total_price} options={{ style: "decimal" }} />}/>
      <LabelDisplay title="Payment Method" description={data?.payment_method}/>
      </div>

    </Modal>
  );
};

export default ProductsDrawer;
