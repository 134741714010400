import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { Button, Modal, Input, Form, Typography, Card, Space, Spin, Divider } from "antd";
import { doPatch } from "../../../../../API/apis";
import Notification from "../../../../../components/Notification/Notification";

const { Paragraph, Text } = Typography;

const Credentials = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { patientBasicInfo, getPatientData } = useStateGlobalContext();

  const showModal = () => setIsModalVisible(true);

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handlePasswordChange = async (values) => {
    try {
      const response = await doPatch(`/patient/update-info/${id}/`, values);
      if (response.status === 200) {
        Notification.success("Password Changed Successfully");
        await getPatientData(id);
        handleCancel();
      }
    } catch (error) {
      console.error("Error updating password:", error);
      Notification.error("Failed to update password. Please try again.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getPatientData(id);
      setLoading(false);
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  return (
    <Card
      title={<Text strong className="text-lg">Patient Credentials</Text>}
      bordered
      className="shadow-sm"
    >
      <Space direction="vertical" size="middle" className="w-full">
        <div>
          <Text strong>Patient Portal Link:</Text>
          <Paragraph copyable className="ml-2 text-blue-600">
            https://patientportal.americanemr.com/
          </Paragraph>
        </div>
        <div>
          <Text strong>Login ID:</Text>
          <Paragraph copyable className="ml-2">{patientBasicInfo?.mrn_number}</Paragraph>
        </div>
        <div>
          <Text strong>Password:</Text>
          <Paragraph className="ml-2">
            {patientBasicInfo?.password.replace(/./g, "●")}
          </Paragraph>
        </div>
        <Divider />
        <Button type="primary" size="large" onClick={showModal}>
          Reset Password
        </Button>
      </Space>

      <Modal
        title="Reset Password"
        width={400}
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="rounded-lg"
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handlePasswordChange}
          className="space-y-4"
        >
          <Form.Item
            name="password"
            label="New Temporary Password"
            rules={[
              { required: true, message: "Please enter a new password!" },
              { min: 6, message: "Password must be at least 6 characters long!" },
            ]}
          >
            <Input.Password placeholder="Enter a new password" />
          </Form.Item>
          <div className="flex justify-end space-x-2">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default Credentials;
