import { Button, Table, Form, Input, Modal, Card, Col } from "antd";
import React, { useEffect, useState } from "react";
import { doPost, doGet, doPatch, doDelete } from "../../../../API/apis";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import Notification from "../../../../components/Notification/Notification";
import BasicButton from "../../../../components/BasicButton/BasicButton";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { getServiceTypeColumn } from "../../../../components/TableColumns/TableColumns";
import CustomTable from "../../../../components/CustomTable/CustomTable";



function ServiceTypeGroup() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editService, setEditService] = useState(null);
  const [serviceTypeLoading, setServiceTypeLoading] = useState(false);
  const [isServiceModalVisible, setIsServiceModalVisible] = useState(false);
  const {submitLoading, setSubmitLoading} = useStateGlobalContext()



  const handleEdit = (record) => {
    setEditService(record);
    setIsServiceModalVisible(true);
    form.setFieldsValue({
      service_type: record.service_type,
    });
  };
  const handleDelete = (id) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: () => {
        deleteServiceType(id);
      },
    });
  };

  const deleteServiceType = async (id) => {
    try {
      const response = await doDelete(
        `/services/service-type/delete/${id}/`
        
      );
      if (response.status === 200) {
        Notification.success("Service type deleted successfully");
        getServiceType();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } 
  };

  const showServiceModal = () => {
    setIsServiceModalVisible(true);
  };

  const handleSubmit = async (values) => {
    setSubmitLoading(true)
    const data = {
        service_type: values.service_type,
      };
    try {
      const response = editService ? await doPatch(
        `/services/service-type/update/${editService.id}/`,
        data
      ) : 
       await doPost(`/services/service-type/add/`, data);
      if (response.status === 200 || response.status === 201) {
        const successMessage = editService
          ? "Service type updated successfully"
          : "Service type added successfully";

        Notification.success(successMessage);
        getServiceType();
        closeServiceModal();
      } else{
        Notification.error("This Service type already exists")
      }
    } catch (error) {
      Notification.error("This service type already exists");
    } finally {
      setSubmitLoading(false);
    }
  };

  const closeServiceModal = () => {
    setIsServiceModalVisible(false);
    setEditService(null);
    form.resetFields();
  };

  const getServiceType = async () => {
    setServiceTypeLoading(true);
    try {
      const response = await doGet(`/services/service-type/get/`);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setServiceTypeLoading(false);
    }
  };


  useEffect(() => {
    getServiceType();
  }, []);

  return (
    <Col xs={24} md={24} lg={12} xl={12} className="mb-4">
    <Card className="">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-bold">Types</h1>
        <Button
          type="primary"
          className="float-right mr-2"
          onClick={showServiceModal}
        >
          Add Type
        </Button>
      </div>
      <CustomTable
       columns={getServiceTypeColumn({handleEdit, handleDelete})}
       dataSource={data}
       rowKey="time_in_pst"
       loading={serviceTypeLoading}
      />
      <Modal
        title={editService ? "Edit Service Type" : "Add Service Type"}
        onCancel={closeServiceModal}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        open={isServiceModalVisible}
        footer={null}
      >
        <Form name="service-type" form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="service_type"
            label="Type Name"
            rules={[{ required: true, message: "Please enter a service type" }]}
          >
            <Input placeholder="Enter service type" maxLength={50} />
          </Form.Item>
          <div className="flex justify-end">
          <Button onClick={closeServiceModal} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <BasicButton loading={submitLoading} saving={!editService} title={editService ? "Update" : "Save"}/>
        </div>
        </Form>

      </Modal>
    </Card>
    </Col>
  );
}

export default ServiceTypeGroup;
