import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, Row, Col, AutoComplete } from "antd";
import { EditOutlined, DeleteOutlined} from "@ant-design/icons";
import { doDelete, doGet, doPatch, doPost } from "../../../../API/apis";
import Notification from '../../../../components/Notification/Notification';
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { NumberFormatter, alphaNumericValidator,  validatePrice } from "../../../../utils/validator";
import BasicButton from "../../../../components/BasicButton/BasicButton";
import cptCodesData from "./data.json";

const SUCCESS_MESSAGES = {
  update: "CPT Codes Updated Successfully",
  add: "CPT Codes Added Successfully",
};

const ERROR_MESSAGES = {
  serviceExists: "CPT Codes Already Exists",
};

const CptCodes = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedCode, setSelectedCode] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const [editingService, setEditingService] = useState(null);
  const {submitLoading, setSubmitLoading} = useStateGlobalContext()

  useEffect(() => {
    setOptions(cptCodesData);
  }, []);

  const handleSearch = (value) => {
    const filteredOptions = cptCodesData?.filter(
      (option) =>
        option?.label?.toLowerCase().includes(value.toLowerCase()) ||
        option?.code?.toString().toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  const onSelect = (value, option) => {
    setSelectedCode(option.key);
    setSelectedLabel(option.props.children[2]);
    form.setFieldsValue({
      serviceCode: option.key,
      description: option.props.children[2], 
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "serial_number",
      width: "5%",
      key: "serial_number",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "CPT Code",
      dataIndex: "service_code",
      key: "service_code",
      width: "10%",
      ellipsis: true,
      searchable:true,
    },
    {
      title: "Description",
      dataIndex: "service_description",
      key: "service_description",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      align: "center",
      width: "10%",
      render: (text) => <NumberFormatter value={text} />,
    },
    {
      title: "Action",
      width: "8%",
      align: "center",
      render: (_, record) => (
        <div>
          <EditOutlined
            className="mr-5 text-[#1890ff]"
            onClick={() => handleEditService(record)}
          />
          <DeleteOutlined
            className="text-[#F44336]"
            onClick={() => handleDelete(record.id)}
          />
        </div>
      ),
    },
  ];

  const showModal = () => {
    setVisible(true);
  };

  const handleEditService = (record) => {
    setEditingService(record);
    form.setFieldsValue({
      serviceCode: record.service_code,
      description: record.service_description,
      price: record.fee,
    });
    setVisible(true);
  };

  const handleSubmit = async ({ serviceCode, description, price }) => {
    const data = {
      service_code: serviceCode,
      service_description: description,
      fee: price,
    };
    setSubmitLoading(true);

    try {
      let response;
      if (editingService) {
        const updatedData = {};
        if (serviceCode !== editingService.service_code) updatedData.service_code = serviceCode;
        if (description !== editingService.service_description) updatedData.service_description = description;
        if (price !== editingService.fee) updatedData.fee = price;

        if (Object.keys(updatedData).length === 0) {
          Notification.info("No changes made");
          return;
        }

        response = await doPatch(`/services/cpt-code/update/${editingService.id}/`, updatedData);
      } else {
        response = await doPost(`/services/cpt-code/add/`, data);
      }
      
      if (response.status === 200 || response.status === 201) {
        const successMessage = editingService ? SUCCESS_MESSAGES.update : SUCCESS_MESSAGES.add;
        Notification.success(successMessage);
        getCPTCodes();
        handleCancel();
      } else {
        Notification.error(ERROR_MESSAGES.serviceExists);
      }
    } catch (error) {
      Notification.error(ERROR_MESSAGES.serviceExists);
    } finally{
      setSubmitLoading(false)
    }
  };

  const getCPTCodes = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/services/cpt-code/get/`);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCPTCodes();
  }, []);

  const handleDelete = (id) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: () => handleDeleteService(id),
    });
  };

  const handleDeleteService = async (id) => {
    setLoading(true);
    try {
      const response = await doDelete(`/services/cpt-code/delete/${id}/`);
      if (response.status === 200) {
        Notification.success("Service Deleted Successfully");
        getCPTCodes();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setTimeout(() => {
      setVisible(false);
      setEditingService(null);
      form.resetFields();
    }, 300);
    
  };

  return (
    <div className="max-w-[1800px] mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-bold">CPT & HCPCS Codes</h1>
        <Button type="primary" onClick={showModal}>
          Add Service Code
        </Button>
      </div>
      <Table
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          total: data?.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />

      <Modal
        title={editingService ? "Edit Service Code" : "Add Service Code"}
        open={visible}
        width={800}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="cpt-codes"
          initialValues={{ remember: true }}
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item label='Search CPT Codes' name={'search-cpt'}>
            <AutoComplete
              onSearch={handleSearch}
              onSelect={onSelect}
              placeholder="Search..."
            >
              {options?.map((option) => (
                <AutoComplete.Option key={option.code} value={option.code}>
                  {option.code} - {option.label}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Service Code"
                name="serviceCode"
                rules={[
                  { required: true, message: "Please input CPT Code!" },
                  { validator: alphaNumericValidator },
                ]}
              >
                <Input maxLength={30} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Please input Description!" },
                ]}
              >
                <Input maxLength={300} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Fee"
                name="price"
                rules={[{ validator: validatePrice }]}
                maxlegth={10}
              >
                <Input maxlegth={15}/>
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end">
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <BasicButton loading={submitLoading} saving={!editingService} title={editingService ? "Update Service" : "Add Service"}/>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default CptCodes;
