import { Button, Card, Divider } from "antd";
import React, { useEffect, useState } from "react";
import "./referralreport.css";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import PrintDrawer from "../../../../../components/GlobalComponents/PrintDrawer";
import ClinicInformation from "../../../../../components/PrescriptionsPad/ClinicInformation";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";
import {
  fullAddress,
  fullName,
} from "../../../../../components/GlobalComponents/GlobalComponents";
import { usePDF } from "react-to-pdf";

const ReferralReport = ({ visible, onClose, data }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    officeSetting,
    getOfficeSettings,
    patientBasicInfo,
    getPatientData,
    patientAllergies,
    getAllergies,
  } = useStateGlobalContext();


  const { toPDF, targetRef } = usePDF({
    filename: "Referral.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };

  const DisplayAllergies = ({ allergies }) => {
    const displayedAllergies = allergies?.map(
      (allergy) => allergy?.allergy_value
    );

    return <span>{displayedAllergies?.join(", ")}</span>;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          getOfficeSettings(),
          getPatientData(id),
          getAllergies(id),
        ]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]); 

  return (
    <PrintDrawer title={"Referral Report"} visible={visible} onClose={onClose}>
      <div cclassName="invoice-print">
      <div className="invoice-container">
      <Button onClick={downloadPDF} className="mb-2" type="primary"> 
            Download PDF
          </Button>
        <Card className="invoice-content" ref={targetRef}>

            <h2 className="text-center mt-4 mb-4">
              Referral ID: {data?.referral_id}{" "}
            </h2>
            <p className="text-center mt-4 mb-4 font-semibold">
              Status:{" "}
              {data?.status === "Cancelled" ? (
                <span className="font-medium text-red-500">{data.status}</span>
              ) : (
                data?.status
              )}
            </p>
            <h2 className="text-center">Referring From</h2>
            <div className="flex justify-between">
              <div className="w-[33%]">
                <p className="font-semibold">Office Info</p>
                {ClinicInformation(officeSetting)}
              </div>
              <div className="w-[33%]">
                <p className="font-semibold">Provider Info</p>
                <LabelDisplay
                  title="Name"
                  description={data?.referral_from_name}
                />
                <LabelDisplay
                  title="Speciality"
                  description={data?.referral_from_speciality}
                />
                <LabelDisplay
                  title="Phone"
                  description={data?.referral_from_phone}
                />
                <LabelDisplay
                  title="Fax"
                  description={data?.referral_from_fax}
                />
                <LabelDisplay
                  title="Email"
                  description={data?.referral_from_email}
                />
              </div>
            </div>
            <Divider
            />
            <div className="flex justify-between">
              <LabelDisplay
                title="Referral Date"
                description={
                  <FormatDateTime date={data?.date_time} type="datetime" />
                }
              />
              <LabelDisplay
                title="Start Date"
                description={<FormatDate date={data?.start_date} />}
              />
              <LabelDisplay
                title="Expiry Date"
                description={<FormatDate date={data?.end_date} />}
              />
              <LabelDisplay
                title="Authorization Code"
                description={<FormatDate date={data?.auth_code} />}
              />
            </div>
            <Divider
            />

            <p className="text-center font-semibold">Patient Demographics</p>
            <div>
              <div className="flex justify-between">
                <LabelDisplay
                  title="Full Name"
                  description={fullName(patientBasicInfo)}
                />
                <LabelDisplay
                  title="Gender"
                  description={
                    patientBasicInfo?.sex === "other"
                      ? patientBasicInfo?.other_Gender
                      : patientBasicInfo?.gender
                  }
                />
                <LabelDisplay
                  title="DOB"
                  description={
                    <FormatDate date={patientBasicInfo?.date_of_birth} />
                  }
                />
              </div>
              <LabelDisplay
                title="Full Address"
                description={fullAddress(patientBasicInfo)}
              />
              <div className="flex justify-between">
                <LabelDisplay
                  title="Phone Number"
                  description={patientBasicInfo?.patient_phone}
                />
                <LabelDisplay
                  title="Email"
                  description={patientBasicInfo?.patient_email}
                />
              </div>
              <p>
                <span className="font-medium">Allergies: </span>
                <span className="text-red-600 font-medium">
                  <DisplayAllergies allergies={patientAllergies} />{" "}
                </span>
              </p>
            </div>
            <Divider
            />

            <p className="text-center font-semibold">Patient Insurance Information</p>
            <div>
              <div className="flex justify-between">
                <LabelDisplay title="Type" description={data?.insurance_type} />
                <LabelDisplay
                  title="Company"
                  description={data?.insurance_company}
                />
                <LabelDisplay title="Plan" description={data?.insurance_plan} />
              </div>
              <div className="flex justify-between">
                <LabelDisplay
                  title="Group #"
                  description={data?.insurance_group}
                />
                <LabelDisplay
                  title="Policy #"
                  description={data?.insurance_policy}
                />
              </div>

            <Divider
            />

            <p className="text-center font-semibold">Referring To</p>
            <div>
              <div className="flex justify-between">
                <LabelDisplay
                  title="Full Name"
                  description={data?.referral_to_full_name}
                />
                <LabelDisplay
                  title="Speciality"
                  description={data?.referral_to_speciality}
                />
              </div>
              <div className="flex justify-between">
                <LabelDisplay
                  title="Phone"
                  description={data?.referral_to_phone}
                />
                <LabelDisplay title="Fax" description={data?.referral_to_fax} />
              </div>
              <LabelDisplay
                title="Direct Email"
                description={data?.referral_to_email}
              />
              <LabelDisplay
                title="Full Address"
                description={data?.referral_to_address}
              />
              <LabelDisplay
                title="Operating Hours"
                description={data?.referral_to_operating_hours}
              />
            </div>
          </div>
          <Divider
          />

          <p className="text-center font-semibold">Clinical Notes</p>
          <LabelDisplay title="Referral For Reason" description={data?.reason_for_referral} />
          <LabelDisplay title="Patient Diagnosis ICD-10 Codes" description={data?.patient_diagnostic_code} />
          <LabelDisplay title="Clinical Notes" description={data?.clinic_notes} />

        </Card>
        </div>
      </div>
    </PrintDrawer>
  );
};

export default ReferralReport;
