import { Form, Button, Input, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { doGet, doPatch, doPost } from "../../../../../API/apis";
import {
  getMaskedCardNumber,
  getUnMaskSpace,
  isValidCardNumber,
} from "../../../../../utils/validator";
import Notification from "../../../../../components/Notification/Notification";
import dayjs from "dayjs";
import Loader from "../../../../../components/Loader/Loader";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";

const { Item } = Form;

const CreditCardInfo = () => {
  const monthFormat = "MM/YY";
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [creditCardDetails, setCreditCardDetails] = useState([]);
  const [showCreditCardNumber, setShowCreditCardNumber] = useState(false);

  const showCreditCardInfo = () => {
    setShowCreditCardNumber(!showCreditCardNumber);
  };

  const showEdit = () => {
    setEdit(!edit);
  };

  const handleCreditCard = async (values) => {
    const { expiration_date, ...otherValues } = values;
    const currentDate = dayjs();

    // Check if the selected expiration date is in the past
    if (expiration_date.isBefore(currentDate, "month")) {
      Notification.error(
        "The card has already expired. Please enter a valid expiry date."
      );
      return;
    }
    const data = {
      ...otherValues,
      expiration_date: expiration_date.format(monthFormat),
    };
    try {
      const url = creditCardDetails?.id
        ? `/administration/account_owner_credit_card_info/update/${creditCardDetails?.id}/`
        : "/administration/account_owner_credit_card_info/add/";

      const response = creditCardDetails?.id
        ? await doPatch(url, data)
        : await doPost(url, data);
      if (response.status === 201 || response.status === 200) {
        Notification.success(
          response.status === 200
            ? "Record Updated Successfully"
            : "Record Added Successfully"
        );
        getCreditCardInfo();
        setEdit(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCreditCardInfo = async () => {
    setLoading(true);
    try {
      const response = await doGet(
        "/administration/account_owner_credit_card_info/get/"
      );
      if (response.status === 200) {
        const creditCardData = response.data[0];
        setCreditCardDetails(response.data[0]);

        // Ensure expiration_date is parsed correctly or set as undefined
        const parsedExpirationDate = creditCardData?.expiration_date
          ? dayjs(creditCardData.expiration_date, monthFormat)
          : undefined;

        form.setFieldsValue({
          card_holder_name: creditCardData?.card_holder_name,
          credit_card_number: creditCardData?.credit_card_number,
          cvv_security_code: creditCardData?.cvv_security_code,
          expiration_date: parsedExpirationDate,
          zipcode: creditCardData?.zipcode,
          billing_address: creditCardData?.billing_address,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCreditCardInfo();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="flex justify-between">
        <p className="font-semibold text-md">Credit Card Info</p>
        <Button onClick={showEdit}>{edit ? "Cancel" : "Edit"}</Button>
      </div>
      {edit ? (
        <Form layout="vertical" form={form} onFinish={handleCreditCard}>
          <Item
            label="Card Holder Name"
            name="card_holder_name"
            rules={[
              { required: true, message: "Please Enter Card Holder Name" },
            ]}
          >
            <Input placeholder="Card Holder Name" maxLength={30} />
          </Item>
          <Item
            label="Card Number"
            name="credit_card_number"
            rules={[
              { required: true, message: "Please enter Card Number" },
              {
                validator: isValidCardNumber,
              },
            ]}
          >
            <Input placeholder="Card Number" maxLength={20} />
          </Item>
          <div className="flex justify-between gap-4">
            <Item
              label="CVV"
              name="cvv_security_code"
              rules={[{ required: true, message: "Please Enter CVV" }]}
            >
              <Input placeholder="Security Code" maxLength={4} />
            </Item>

            <Item
              label="Expiry MM/YY"
              name="expiration_date"
              rules={[
                { required: true, message: "Please Select Valid Thru Date" },
              ]}
            >
              <DatePicker
                picker="month"
                placeholder="MM/YY"
                format={monthFormat}
              />
            </Item>
            <Item
              label="Billing Zip Code / Postal Code"
              name="zipcode"
              rules={[
                { required: true, message: "Please Enter Billing Address" },
              ]}
            >
              <Input
                placeholder="Billing Zip Code / Postal Code"
                maxLength={30}
              />
            </Item>
          </div>

          <Item label="Billing Address" name="billing_address">
            <Input.TextArea
              placeholder="Billing Address"
              showCount
              maxLength={300}
            />
          </Item>
          <Button type="primary" htmlType="submit">
            {creditCardDetails?.id ? "Update" : "Submit"}
          </Button>
        </Form>
      ) : (
        <div className="view-wrapper">
          <div>
            <LabelDisplay
              title="Card Holder Name"
              description={creditCardDetails?.card_holder_name}
            />
            <div className="flex">
              <LabelDisplay
                title="Card Number"
                description={
                  showCreditCardNumber
                    ? getUnMaskSpace(creditCardDetails?.credit_card_number) || "N/A"
                    : getMaskedCardNumber(creditCardDetails?.credit_card_number) || "N/A"
                }
              />
              {creditCardDetails && (
                <Button
                  type="primary"
                  className="ml-4"
                  size="small"
                  onClick={showCreditCardInfo}
                >
                  {showCreditCardNumber ? "Hide" : "Show"}
                </Button>
              )}
            </div>
            <LabelDisplay
              title="CVV"
              description={creditCardDetails?.cvv_security_code}
            />
            <LabelDisplay
              title="Expiry Date"
              description={creditCardDetails?.zipcode}
            />
            <LabelDisplay
              title="Billing Zip Code / Postal Code"
              description={creditCardDetails?.zipcode}
            />
            <LabelDisplay
              title="Billing Address"
              description={creditCardDetails?.billing_address}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreditCardInfo;
