
import Loader from '../../../../../components/Loader/Loader'
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import LabelDisplay from '../../../../../components/LabelDisplay/LabelDisplay';

function ViewOrderedLabs({ orderedLabData  }) {
  if(!orderedLabData){
    return <Loader/>
  }
  return (
    <div>
      <LabelDisplay title="Added By" description={orderedLabData?.added_by}/>
      <LabelDisplay title="Date Added" description={<FormatDateTime date={orderedLabData?.date_added} type="datetime"/>}/>
      <LabelDisplay title="Lab Type" description={orderedLabData?.lab_id?.includes("LAB_EX") ? "External" : "In-House"}/>
      <LabelDisplay title="Lab Order ID" description={orderedLabData?.lab_id}/>

      {orderedLabData && orderedLabData?.lab_name?.map((lab_item, index) => (
        <div key={index} className="mt-3 bg-slate-100 p-3">
          <LabelDisplay title="Lab" description={lab_item?.lab_name}/>

          {lab_item?.lab_instructions && (
            <LabelDisplay title="Instructions" description={lab_item?.lab_instructions}/>
          )}
          {lab_item?.lab_diagnosis && (
            <LabelDisplay title="Diagnosis" description={lab_item?.lab_diagnosis}/>
          )}
          {lab_item?.lab_type && (
            <LabelDisplay title="Type" description={lab_item?.lab_type}/>
          )}
          {lab_item?.lab_group && (
            <LabelDisplay title="Group" description={lab_item?.lab_group}/>
          )}
          {lab_item?.lab_price && (
            <LabelDisplay title="Price" description={lab_item?.lab_price}/>
          )}
        </div>
      ))}
      <LabelDisplay title="Status" description={orderedLabData?.status}/>
      <LabelDisplay title="Completed By" description={orderedLabData?.completed_by}/>
      {orderedLabData?.completed_date && <LabelDisplay title="Completed Date" description={<FormatDateTime date={orderedLabData?.completed_date} type="datetime"/>}/>}
    </div>
  );
}

export default ViewOrderedLabs;
