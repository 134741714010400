import React, { useEffect, useState } from "react";
import { Card, Col, Table, Dropdown, Tooltip, Typography, Button } from "antd";
import { doPatch } from "../../../../../../../API/apis";
import { useParams } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import LabInvoicesDrawer from "./LabInvoicesDrawer";
import { useStateGlobalContext } from "../../../../../../../contexts/GlobalContext";
import LabPad from "./component/LabPad";
import ViewLabInvoice from "./component/ViewLabInvoice";
import { NumberFormatter } from "../../../../../../../utils/validator";
import showConfirmModal from "../../../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../../../components/FormatDate/FormatDateTime";
import Notification from "../../../../../../../components/Notification/Notification";
import { invoiceStatus } from "../../../../../../../utils/Data";
import { InvoiceStatus } from "../../../../../../../components/GlobalComponents/StatusLabels";
import CustomTable from "../../../../../../../components/CustomTable/CustomTable";

const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Collect Payment",
  },
  {
    key: 3,
    label: "Print",
  },
  {
    key: 4,
    label: "Cancel Invoice",
  },
];

const LabInvoices = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceDrawer, setInvoiceDrawer] = useState(false);
  const [labPrintDrawer, setLabPrintDrawer] = useState(false)
  const [labPrintData, setLabPrintData]=useState(null)
  const { labInvoice, getLabInvoices} = useStateGlobalContext();
  const [labView, setLabView] = useState(false)
  const [labViewData, setLabViewData] = useState([])

  const coloumn = [
    {
      key: 2,
      title: "Invoice ID",
      ellipsis: true,
      dataIndex: "inhouseUID",
      align: "center",
    },

    {
      ke: 3,
      title: "Lab Name",
      align: "center",
      dataIndex: "lab_name",
      render: (labs, record) => {
    
        if (labs && labs.length > 0) {
          const labNames = [];
    
          for (let i = 0; i < Math.min(labs.length, 3); i++) {
            const lab = labs[i];
            if (lab.lab_name) {
              labNames.push(lab.lab_name);
            }
          }
    
          const displayText = labs.length > 3 ? labNames.join(", ") + "..." : labNames.join(", ");
          
          return (
            <Button onClick={()=>handleLabView(record)} type="link">
               {displayText}
            </Button>
          );
        } else {
          return (
            <div style={{ cursor: 'pointer' }}>
              N/A
            </div>
          );
        }
      },
    },
    {
      ke: 4,
      title: "Issue Date",
      align: "center",
      dataIndex: "issue_date",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
      ),
    },
    {
      ke: 5,
      title: "Added By",
      align: "center",
      dataIndex: "added_by",
    },
    {
      ke: 6,
      title: "Price",
      align: "center",
      dataIndex: "total_price",
      render: (text)=> <NumberFormatter value={text} options={{ style: "decimal" }} />
    },
    {
      ke: 6,
      title: "Status",
      align: "center",
      dataIndex: "status",
      filters: invoiceStatus?.map((text) => ({
        text: text.label,
        value: text.label,
      })),
      onFilter: (value, record) =>
        record?.status?.toLowerCase().includes(value.toLowerCase()),
      defaultFilteredValue: ["Paid", "unpaid"], 
      render: (status) =>
        <InvoiceStatus status={status}/>
    },
    {
      key: 7,
      title: "Action",
      align: "center",
      render: (_, record) => {
        const { status } = record;
        const availableItems =
          status === "Paid"
            ? items.filter((item) => [1, 3,4].includes(item.key))
            : status === 'unpaid'? items.filter((item) => [1, 2, 3, 4].includes(item.key)) 
            : status === 'Cancelled' ? items.filter((item) => [1, 3].includes(item.key))
            : items.filter((item) => [1, 3].includes(item.key))
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 4) {
                   handleCancelInvoice(record);
                  } else if (item.key === 2) {
                    handleView(record, true);
                  } else if (item.key === 1) {
                    handleLabView(record)
                  } else if (item.key === 3) {
                    handlePrint(record)
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

 
  const handlePrint = (record)=>{
    setLabPrintDrawer(true)
    setLabPrintData(record)
  }

  const closePrint = ()=>{
    setLabPrintDrawer(false)
  }

  const handleLabView = (record)=>{
    setLabView(true)
    setLabViewData(record)
  }

  const closeLabView=()=>{
    setLabView(false)
  }

  const handleCancelInvoice = (record)=>{
    showConfirmModal({
      title: "Confirm Cancel?",
      icon: null,
      content: "Are you sure you want to Cancel this?",
      okText: "Cancel Invoice",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        cancelLabInvoice(record);
      },
    });
  }

  const cancelLabInvoice = async (record) => {
    let data = {};
    data = {
      status: "Cancelled",
    };
    try {
      const response = await doPatch(
        `/labs/images/update/${id}/${record?.inhouseUID}/`,
        data
      );
      if (response.status === 200) {
        Notification.success("Lab Invoice Cancelled Successfully");
        getLabInvoices(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleView = (record) => {
    setInvoiceData(record);
    setInvoiceDrawer(true);
  };

  const onClose = () => {
    setInvoiceDrawer(false);
  };

  useEffect(() => {
    async function fetchData(){
      setLoading(true)
      await getLabInvoices(id);
      setLoading(false)
    }
    fetchData()
  }, [id]);

  return (
    <>
        <Card className="shadow-class">
          <h1 className="mb-2">Labs Invoices </h1>
          <CustomTable
            size="small"
            columns={coloumn}
            loading={loading}
            dataSource={labInvoice}
            pagination={true}
          />
        </Card>

      <LabInvoicesDrawer
        visible={invoiceDrawer}
        data={invoiceData}
        onClose={onClose}
      />

      <LabPad visible={labPrintDrawer} onClose={closePrint} rxData={labPrintData}/>
      <ViewLabInvoice visible={labView} onClose={closeLabView} rxData={labViewData}/>
    </>
  );
};

export default LabInvoices;
