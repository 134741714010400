import React, { useEffect, useState } from "react";
import { Table, Col, Card, Button, Tooltip } from "antd";
import { useNavigate, useOutletContext } from "react-router-dom";
import { formatTime } from "../../../../utils/DateFormat";
import { doGet } from "../../../../API/apis";
import { SlRefresh } from "react-icons/sl";
import { DateTime } from "luxon";
import Notification from "../../../../components/Notification/Notification";
import CustomTable from "../../../../components/CustomTable/CustomTable";

const Appointments = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [upcomingAppoinments, setUpcomingAppointments] = useState([]);
  const [officeDateFormat, officeTimeFormat, officeTimezone] =
    useOutletContext();

  const coloumn = [
    {
      key: 1,
      title: "Time",
      ellipsis: true,
      width: "12%",
      align: "center",
      dataIndex: "appointment_date",
      render: (text, record) => (
        <span title={`${record?.duration} minutes`}>
          {" "}
          {formatTime(text, officeTimeFormat, officeTimezone)}{" "}
        </span>
      ),
    },
    {
      key: 2,
      title: "Patient Name",
      ellipsis: true,
      render : (_, record)=>(
        <Button size="small" type="link"
        onClick={() => {
          navigate(`/main/charts/patient/${record?.patient_id}/`);
        }}
        >{record?.first_name} {record?.middle_name? record?.middle_name : " "} {record?.last_name}</Button>
      )
    },
    {
      key: 3,
      title: "Reason for Visit",
      ellipsis: true,
      dataIndex: "visit_reason",
    },
    {
      key: 4,
      title: "Provider",
      align: "center",
      ellipsis: true,
      dataIndex: "appointment_with",
    },
    {
      key: 5,
      title: "Status",
      align: "center",
      ellipsis: true,
      dataIndex: "current_status",
      render: (text) => {
        let bgColor = "";
        let textColor = "";
        switch (text) {
          case "Scheduled":
            bgColor = "bg-blue-200";
            textColor = "text-blue-500";
            break;
          case "Confirmed":
            bgColor = "bg-green-200";
            textColor = "text-green-500";
            break;
          case "Rescheduled":
            bgColor = "bg-blue-200";
            textColor = "text-blue-500";
            break;
          case "Late Arrival":
            bgColor = "bg-yellow-200";
            textColor = "text-yellow-500";
            break;
          case "No Show":
            bgColor = "bg-red-200";
            textColor = "text-red-500";
            break;
          case "Cancelled":
            bgColor = "bg-red-200";
            textColor = "text-red-500";
            break;
          default:
            bgColor = "bg-green-700";
            textColor = "text-white";
            break;
        }
        return (
          <span
            className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}
          >
            {text}
          </span>
        );
      },
    },
  ];

  const getUpcomingAppointments = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/appointments/up-coming-appointments/`);
      if (response.status === 200) {
        const now = DateTime.now().setZone(officeTimezone); 
        const filteredAppointments = response.data.filter(appointment => {
          const appointmentTime = DateTime.fromISO(appointment.appointment_date, { zone: officeTimezone || 'US/Central' }); 
          return appointmentTime >= now; 
        });

        const sortedAppointments = filteredAppointments.sort(
          (a, b) => new Date(a.appointment_date) - new Date(b.appointment_date)
        );

        setUpcomingAppointments(sortedAppointments);
      }
    } catch (error) {
      Notification.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewAllAppointments = () => {
    navigate("/main/appointments/today-appointments");
  };

  useEffect(() => {
    getUpcomingAppointments();
  }, []);

  return (
    <Col xl={12}>
      <Card className="mt-4 shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h1 className="font-bold text-lg">Today's Upcoming Appointments </h1>
          <div>
            <Button onClick={getUpcomingAppointments} icon={<SlRefresh/>} iconPosition={"end"} className="mr-2">
            Refresh 
          </Button>
            <Button onClick={handleViewAllAppointments}>
              View All
            </Button>
          </div>
        </div>
        <CustomTable
          columns={coloumn}
          dataSource={upcomingAppoinments}
          loading={loading}
          scroll={{ y: 200 }}
          pagination={false}
        />
      </Card>
    </Col>
  );
};

export default Appointments;
