import React from "react";
import ServiceType from "./ServicesType";
import Groups from './Groups'
import { Row } from "antd";

const TypesAndGroups = () => {
  return (
    <Row gutter={[16, 16]}>
      <ServiceType />
      <Groups/>
    </Row>
  );
};

export default TypesAndGroups;
