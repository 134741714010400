import React, { useState } from "react";
import { Card, Form, DatePicker, Button } from "antd";
import dayjs from "dayjs";
import Notification from "../Notification/Notification";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import { NumberFormatter } from "../../utils/validator";

const { RangePicker } = DatePicker;

const DateRangePickerCard = ({
  onDateChange,
  onSearch,
  onReset,
  totalGrandTotal,
}) => {
  const [form] = Form.useForm();
  const { officeSetting } = useStateGlobalContext();
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const officeDateFormat =
    officeSetting?.date_format === "en-US"
      ? "MM/DD/YYYY"
      : officeSetting?.date_format === "en-GB"
      ? "DD/MM/YYYY"
      : "YYYY/MM/DD";

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
    onDateChange(dates);
  };

  const handleResetForm = () => {
    form.resetFields();
    setSelectedDateRange(null);
    onReset();
  };

  const handleSearchClick = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      onSearch(selectedDateRange);
    } else {
      Notification.warning("Please select a valid date range");
    }
  };

  return (
    // <Card className="shadow-class mb-4 mt-4">
      <Form layout="vertical" form={form}>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Form.Item
              label="Select Date Range"
              name="date_range"
              className="mr-4"
            >
              <RangePicker
                size="medium"
                format={officeDateFormat}
                onChange={handleDateRangeChange}
                presets={rangePresets}
              />
            </Form.Item>
            <Button type="primary" className="ml-2" onClick={handleSearchClick}>
              Search
            </Button>
            <Button className="ml-2" onClick={handleResetForm}>
              Reset
            </Button>
          </div>
          {/* {totalGrandTotal && <div className="flex items-center bg-gradient-to-r from-blue-100 via-white to-blue-50 p-4 shadow-md rounded-lg border border-blue-200">
            <div className="flex items-center space-x-4">
              <div className="p-2 bg-blue-500 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8c-1.656 0-3 1.344-3 3 0 1.656 1.344 3 3 3s3-1.344 3-3c0-1.656-1.344-3-3-3zm0 10.5c-4.128 0-7.5-3.372-7.5-7.5S7.872 3.5 12 3.5s7.5 3.372 7.5 7.5-3.372 7.5-7.5 7.5z"
                  />
                </svg>
              </div>
              <div>
                <h3 className="text-gray-700 text-lg font-semibold">
                  Total Revenue
                </h3>
                <p className="text-2xl font-extrabold text-blue-600">
                  <NumberFormatter value={totalGrandTotal ?? 0} />
                </p>
              </div>
            </div>
          </div>} */}
        </div>
      </Form>
    // </Card>
  );
};

export default DateRangePickerCard;
