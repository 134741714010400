import { Avatar, Button, Card, Divider, Table } from "antd";
import "./prescriptionpad.css";
import { usePDF } from "react-to-pdf";
import ClinicInformation from "../../../../components/PrescriptionsPad/ClinicInformation";
import LabelDisplay from "../../../../components/LabelDisplay/LabelDisplay";
import { NumberFormatter } from "../../../../utils/validator";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import { Status } from "../../../../utils/Data";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import InvoiceFooter from "../../PatientSummary/InvoiceAndBilling/Invoices/component/InvoiceFooter/InvoiceFooter";
import PrintDrawer from "../../../../components/GlobalComponents/PrintDrawer";

const coloumn = [
  {
    key: 1,
    title: "#",
    render: (_, record, index) => index + 1,
  },
  {
    key: 2,
    title: "Product Name",
    dataIndex: "items",
  },
  {
    key: 3,
    title: "Quanity",
    dataIndex: "quantity",
    align: "center",
  },
  {
    key: 4,
    title: "Price",
    dataIndex: "price",
    render: (price) => <NumberFormatter value={price} />,
  },
];

const PrescriptionPad = ({ visible, onClose, rxData, clinic_information }) => {

  const { toPDF, targetRef } = usePDF({
    filename: `${rxData?.invoice_id}.pdf` || "Invoice.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };

  // const contentRef = useRef(null);
  // const handlePrint = useReactToPrint({
  //   content: () => contentRef.current,
  // });

  const { officeSetting } = useStateGlobalContext();

  return (
    <PrintDrawer
      title={rxData?.patient_name}
      visible={visible}
      onClose={onClose}
    >
      <div className="invoice-print">
        <div className="invoice-container">
          <Button onClick={downloadPDF} className="mb-2" type="primary"> 
            Download PDF
          </Button>
          <Card className="invoice-content" ref={targetRef}>
            <div className="py-2 text-slate-600 bg-slate-200 px-2 font-semibold">
              <div className="flex items-center justify-between">
                <Avatar
                  src={officeSetting?.user_office_logo}
                  alt="EMR"
                  className="w-12"
                />
                <div>
                  Invoice ID: {rxData?.invoice_id}{" "}
                  <Status status={rxData?.status} />
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-2">
              <div className="text-left">
                <p className="font-semibold">Facility Information</p>
                {ClinicInformation(clinic_information, "text-left")}
              </div>
              <div className="text-left">
                <p className="font-semibold">Customer Information </p>
                <LabelDisplay
                  title={"Name"}
                  description={rxData?.patient_name}
                />
                <LabelDisplay
                  title={"Date"}
                  description={
                    <FormatDateTime date={rxData?.issue_date} type="datetime" />
                  }
                />
              </div>
            </div>

            <Divider />
            <div className="print-content">
              <Card>
              <p className="font-semibold">Medication</p>
              <Table
                className="mt-2 mb-10"
                size="small"
                columns={coloumn}
                dataSource={rxData?.product_details}
                pagination={false}
              />
              </Card>

              <InvoiceFooter
                subTotal={rxData?.price}
                discount={rxData?.discount}
                discountAmount={rxData?.discounted_amount}
                tax={rxData?.tax}
                grandTotal={rxData?.grand_total}
              />

              {/* <div className="flex flex-col items-end p-2 prescription-total">
                <LabelDisplay
                  title={"Sub Total"}
                  description={<NumberFormatter value={rxData?.price} />}
                />
                <LabelDisplay
                  title={`Discount ${rxData?.discount}%`}
                  description={
                    <NumberFormatter value={rxData?.discounted_amount} />
                  }
                />
                <LabelDisplay
                  title={"Tax"}
                  description={<NumberFormatter value={rxData?.tax} />}
                />
                <LabelDisplay
                  title={"Grand Total"}
                  description={<NumberFormatter value={rxData?.grand_total} />}
                />
              </div> */}

              <div className="flex justify-end flex-col items-end mt-10 signature">
                <p>_______________________________</p>
                <h3>Signature</h3>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </PrintDrawer>
  );
};

export default PrescriptionPad;
