import { Button, Card, Divider } from "antd";
import React, { useEffect, useState } from "react";
import "./labprescription.css";
import { usePDF } from "react-to-pdf";
import LabImage from "../../../../../assets/lab.png";
import providerInfo from "../../../../../components/PrescriptionsPad/providerInfo";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import PreferredItem from "../../../../../components/PrescriptionsPad/PreferredItem";
import { useParams } from "react-router-dom";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import ClinicInformation from "../../../../../components/PrescriptionsPad/ClinicInformation";
import {  fullName, InvoiceBar } from "../../../../../components/GlobalComponents/GlobalComponents";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";
import PatientInformation from "../../../../../components/PrescriptionsPad/PatientInformation";
import PrintDrawer from "../../../../../components/GlobalComponents/PrintDrawer";
import { InvoiceStatus } from "../../../../../components/GlobalComponents/StatusLabels";

const LabPrescription = ({ visible, rxData, officeData, ptData, onClose }) => {
  const {id} = useParams()
  const [loading, setLoading] = useState(true)
  const {providerDetails , getProviderDetails ,preferredLab: preferredData,
    getPreferredLab, } = useStateGlobalContext()
    


  useEffect(() => {
    if (rxData?.provider_id) {
      getProviderDetails(rxData?.provider_id);
      setLoading(false)
    }
  }, [rxData?.provider_id]);

  useEffect(()=>{
    getPreferredLab(id)
  },[id])

  const { toPDF, targetRef } = usePDF({
    filename: `${rxData?.invoice_id}.pdf` || "Invoice.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };
  
  return (

      <PrintDrawer title={fullName(ptData)} visible={visible} onClose={onClose}>
      {!loading && <div className="lab-pres-print">
        <div className="lab-pres-container">
        <Button onClick={downloadPDF} className="mb-2" type="primary">
            Download PDF
          </Button>
          <Card className="lab-content" ref={targetRef}>
            <InvoiceBar invoice={rxData}/>
            <h2 className="text-center mt-4 mb-4">Lab Order</h2>
            <div className="pres-header">
              <div className="clinic-info w-[33%]">
                <p className="font-semibold">Facility Information</p>
                {ClinicInformation(officeData)}
              </div>

              <div className="lab-info w-[33%]">
                <h4>Preferred Lab</h4>
               {PreferredItem(preferredData)}
                <div>
                  <img
                    src={LabImage}
                    alt="Prescription-Rx"
                    className="flex h-[90px]"
                  />
                </div>
              </div>
            </div>

            <Divider/>
            <div className="flex justify-between">
              <PatientInformation patient={ptData}/>
              <div>
                <LabelDisplay title="Lab Order Date" description={rxData && <FormatDateTime date={rxData?.date_added} type="datetime"/>}/>
              </div>
            </div>

            <Divider/>

            {rxData?.lab_name?.map((lab, idx) => (
              <div key={lab?.key}>
                <div className="flex">
                  <p className="mr-2 pt-1.5">{idx + 1}.</p>
                  <div className="lab-details">
                    <LabelDisplay title="Lab" description={lab?.lab_name}/>
                    <LabelDisplay title="Diagnosis" description={lab?.lab_diagnosis}/>
                    <LabelDisplay title="Instructions" description={lab?.lab_instructions}/>
                  </div>
                </div>
                <Divider/>
              </div>
            ))}

            <div className="flex justify-between">
              {providerInfo(providerDetails)}
              <div className="flex items-end">
                <div>
                  <p>_______________________________</p>
                  <h3 className="flex justify-end">Signature</h3>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>}
      </PrintDrawer>
  );
};

export default LabPrescription;
