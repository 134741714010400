import { Button, Card, Table } from "antd";
import React, { useEffect,  useState } from "react";
// import { useReactToPrint } from "react-to-print";
import InvoiceHeader from "../../../../../../../../components/InvoiceHeader/InvoiceHeader";
import { useParams } from "react-router-dom";
import { useStateGlobalContext } from "../../../../../../../../contexts/GlobalContext";
import "./serviceInvoicepad.css";
import { usePDF } from "react-to-pdf";
import Notification from "../../../../../../../../components/Notification/Notification";
import InvoiceFooter from "../../InvoiceFooter/InvoiceFooter";
import { NumberFormatter } from "../../../../../../../../utils/validator";
import PrintDrawer from "../../../../../../../../components/GlobalComponents/PrintDrawer";
import { InvoiceBar } from "../../../../../../../../components/GlobalComponents/GlobalComponents";
const coloumn = [
  {
    key: 1,
    title: "#",
    render: (_, record, index) => index + 1,
  },
  {
    key: 2,
    title: "Service Name",
    dataIndex: "service_name",
  },
  {
    key: 3,
    title: "QTY",
    dataIndex:"quantity"
  },
  {
    key: 4,
    title: "Unit Price",
    dataIndex: "price",
    align: "center",
    render: (text) => <NumberFormatter value={text} />,
  },
  {
    key: 5,
    title: "Total Price",
    dataIndex: "price",
    align: "center",
    render: (text) => <NumberFormatter value={text} />,
  },
];

const ServiceInvoicePad = ({ visible, onClose, rxData }) => {
  const { id } = useParams();
  // const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const {
    officeSetting,
    patientBasicInfo,
    getPatientData,
    billingInformation,
  } = useStateGlobalContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          getPatientData(id),
        ]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  // const handlePrint = useReactToPrint({
  //   content: () => contentRef.current,
  // });

  const { toPDF, targetRef } = usePDF({
    filename: `${rxData?.invoice_id}.pdf` || "Invoice.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };

  return (

      <PrintDrawer title={rxData?.patient_name} visible={visible} onClose={onClose}>
      <div className="invoices-print">
        <div className="invoice-container">
        <Button onClick={downloadPDF} className="mb-2" type="primary">
            Download PDF
          </Button>
          <Card
            className="invoice-content w-full mx-auto rounded-none"
            ref={targetRef}
          >
            <InvoiceBar invoice={rxData}/>
            <InvoiceHeader
              officeInformation={officeSetting}
              patientInformation={patientBasicInfo}
              rxData={rxData}
              billingInformation={billingInformation}
            />
            <Card>
            <Table
              columns={coloumn}
              size="small"
              dataSource={[rxData]}
              pagination={false}
              className="mt-2"
            />
            </Card>
            <InvoiceFooter
              subTotal={rxData?.price}
              discount={rxData?.discount}
              discountAmount={rxData?.discounted_amount}
              tax={rxData?.tax}
              grandTotal={rxData?.grand_total_amount}
            />
          </Card>

        </div>
      </div>
      </PrintDrawer>
  );
};

export default ServiceInvoicePad;
