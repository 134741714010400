import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Input,
  Modal,
  Button,
  Row,
  Col,
  Select,
  AutoComplete,
} from "antd";
import { doDelete, doPatch, doPost } from "../../../../API/apis";
import {
  validatePrice,
  validateServiceName,
} from "../../../../utils/validator";
import { useStateServicesContext } from "../../../../contexts/ServicesContext";
import Notification from "../../../../components/Notification/Notification";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import { useSearchLabs } from "../../../../API/apiUtils";
import BasicButton from "../../../../components/BasicButton/BasicButton";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { getLabsListColumn } from "../../../../components/TableColumns/TableColumns";
import CustomTable from "../../../../components/CustomTable/CustomTable";
const { Option } = Select;

const LabList = () => {
  const [form] = Form.useForm();
  const [isModal, setIsModal] = useState(false);
  const [editService, setEditService] = useState(null);
  const [loading, setLoading] = useState(false);
  const {submitLoading, setSubmitLoading} = useStateGlobalContext()
  const { group, getGroup, serviceType, getServiceType, labs, getLabs } =
    useStateServicesContext();
  const { options, handleSearchLabs } = useSearchLabs();



  const handleEdit = (record) => {
    setEditService(record);
    form.setFieldsValue({
      service_name: record.service_names,
      service_type: record.service_type,
      service_group: record.service_group_name,
      service_price: record.price,
    });
    setIsModal(true);
  };
  const openModal = () => {
    setIsModal(true);
  };

  const handleSubmit = async (values) => {
    setSubmitLoading(true)
    try {
      const data = {
        service_names: values.service_name,
        service_type: values.service_type || "",
        service_group_name: values.service_group || "",
        price: values.service_price || 0,
      };

      let response;
      if (editService) {
        const updatedData = {};

        // Check if each field has changed and add it to the updatedData object
        if (editService.service_names !== values.service_name) {
          updatedData.service_names = values.service_name;
        }
        if (editService.service_type !== values.service_type) {
          updatedData.service_type = values.service_type;
        }
        if (editService.service_group_name !== values.service_group) {
          updatedData.service_group_name = values.service_group;
        }
        if (editService.price !== values.service_price) {
          updatedData.price = values.service_price;
        }
        if (Object.keys(updatedData).length === 0) {
          Notification.error("No Changes Detected");
          return;
        }
        response = await doPatch(
          `/services/update-labs/${editService.id}/`,
          updatedData
        );
      } else {
        response = await doPost(`/services/add-labs/`, data);
      }

      if (response.status === 200 || response.status === 201) {
        const successMessage = editService
          ? "Lab Updated Successfully"
          : "Lab Added Successfully";

        Notification.success(successMessage);
        getLabs();
        handleCancel();
      } else {
        Notification.error("Lab Already Exists");
      }
    } catch (error) {
      Notification.error("Lab Already Exists");
    } finally{
      setSubmitLoading(false)
    }
  };

  const handleCancel = () => {
    setIsModal(false);
    setEditService(null);
    form.resetFields();
  };

  const handleDelete = async (id) => {
    setLoading(true)
    try {
      const response = await doDelete(`/services/delete-labs/${id}/`);
      if (response.status === 200) {
        Notification.success("Lab Deleted Successfully");
        getLabs();
      }
    } catch (error) {
      console.error(error);
    } finally{setLoading(false)}
  };

  const showDeleteConfirm = (id) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        handleDelete(id);
      },
    });
  };

  const filterUsers = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([getServiceType(), getGroup(), getLabs()]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="max-w-[1800px] mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-bold ">Labs & Imaging</h1>
        <Button type="primary" onClick={openModal}>
          Add Lab
        </Button>
      </div>
      <CustomTable
        columns={getLabsListColumn({handleEdit, showDeleteConfirm})}
        dataSource={labs}
        loading={loading}
        rowKey={"lab_list"}
      />


      <Modal
        width={1000}
        title={editService ? "Edit Lab" : "Add Lab"}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        onCancel={handleCancel}
        open={isModal}
        footer={null}
      >
        <Form
          form={form}
          name="service-list"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="service_name"
                label="Lab Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter service name",
                  },
                  {
                    validator: validateServiceName,
                  },
                ]}
              >
                <AutoComplete
                  onSearch={handleSearchLabs}
                  options={options}
                  placeholder="Search / Add Lab"
                  maxLength={50}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="service_type" label="Type">
                <Select
                  showSearch
                  placeholder="Select Type"
                  filterOption={filterUsers}
                >
                  {serviceType &&
                    serviceType?.map((item) => (
                      <Option key={item.id} value={item.service_type}>
                        {item.service_type}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="service_group" label="Group">
                <Select
                  showSearch
                  placeholder="Select Group"
                  filterOption={filterUsers}
                >
                  {group &&
                    group?.map((item) => (
                      <Option key={item.id} value={item.group}>
                        {item.group}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="service_price"
                label="Price"
                rules={[
                  {
                    validator: validatePrice,
                  },
                ]}
              >
                <Input maxLength={15}/>
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end">
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <BasicButton loading={submitLoading} saving={!editService} title={editService ? "Update" : "Save"}/>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default LabList;
