import React from 'react';

function LabelDisplay({ title, description, className="flex items-center mt-[2px]" }) {
  return (

      <div className={`gap-1 py-1 ${className}`}>
        <div className="font-light flex flex-col leading-none  text-neutral-900">
          <div>{title}:</div>
        </div>
        <div className="flex-1 shrink leading-5 basis-0">{description ?? "N/A"}</div>
      </div>
  );
}

export default LabelDisplay;
