import React from "react";
import { Card } from "antd";
import { DollarOutlined, WalletOutlined, CheckCircleOutlined, AppstoreOutlined } from "@ant-design/icons";

const DashboardCards = () => {
  const data = [
    {
      title: "Total Revenue",
      value: "$50,000",
      icon: <DollarOutlined className="text-2xl text-white" />,
    },
    {
      title: "Total Services Unpaid",
      value: "15",
      icon: <WalletOutlined className="text-2xl text-white" />,
    },
    {
      title: "Total Services Paid",
      value: "85",
      icon: <CheckCircleOutlined className="text-2xl text-white" />,
    },
    {
      title: "Total Services",
      value: "100",
      icon: <AppstoreOutlined className="text-2xl text-white" />,
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 mt-4">
      {data.map((item, index) => (
        <Card
          key={index}
          className={`shadow-md rounded-lg bg-gradient-to-r from-blue-200 via-blue-50 to-blue-100 text-neutral-900 p-1`}
          hoverable={true}
        >
          <div className="flex items-center space-x-4">
            {/* <div className="p-4 rounded-full bg-white shadow">
              {item.icon}
            </div> */}
            <div>
              <h3 className="text-lg font-semibold">{item.title}</h3>
              <p className="text-2xl font-bold">{item.value}</p>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default DashboardCards;
