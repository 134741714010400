import React from "react";
import LabelDisplay from "../LabelDisplay/LabelDisplay";
import { ageCalculator } from "../../utils/ageCalculator";
import { fullAddress, fullName } from "../GlobalComponents/GlobalComponents";
import FormatDate from "../FormatDate/FormatDate";

const PatientInformation = ({ patient }) => {
  return (
    <div className="patient-info">
      <p className="font-semibold">Patient Information</p>
      <LabelDisplay title="Name" description={fullName(patient)} />
      <LabelDisplay
        title="DOB"
        description={<FormatDate date={patient?.date_of_birth} />}
      />
      <LabelDisplay
        title="Age"
        description={`${ageCalculator(patient?.date_of_birth)} y/o`}
      />
      <LabelDisplay title="Gender" description={patient?.gender} />
      <LabelDisplay title="Address" description={fullAddress(patient)} />
      <LabelDisplay
        title="Phone"
        description={patient && patient?.patient_phone}
      />
    </div>
  );
};

export default PatientInformation;
