import {  Button, Card, Table } from "antd";
import InvoiceHeader from "../../../../../../../../components/InvoiceHeader/InvoiceHeader";
import { useStateGlobalContext } from "../../../../../../../../contexts/GlobalContext";
import { NumberFormatter } from "../../../../../../../../utils/validator";
import InvoiceFooter from "../../InvoiceFooter/InvoiceFooter";
import PrintDrawer from "../../../../../../../../components/GlobalComponents/PrintDrawer";
import { usePDF } from "react-to-pdf";
import { InvoiceBar } from "../../../../../../../../components/GlobalComponents/GlobalComponents";

const coloumn = [
  {
    key: 1,
    title: "#",
    align: "center",
    render: (_, record, index) => index + 1,
  },
  {
    key: 2,
    title: "Sale Item",
    align: "center",
    dataIndex: "items",
  },
  {
    key: 3,
    title: "QTY",
    align: "center",
    dataIndex: "quantity",
  },
  {
    key: 4,
    title: "Unit Price",
    align: "center",
    dataIndex: "unit_price",
    render:(text)=> <NumberFormatter value={text}/>
  },
  {
    key: 5,
    title: "Total Price",
    align: "center",
    dataIndex: "price",
    render:(text)=> <NumberFormatter value={text}/>
  },
];


const PrescriptionPad = ({ visible, onClose, rxData }) => {
  const {
    officeSetting,
    patientBasicInfo,
    billingInformation,
  } = useStateGlobalContext();


  const { toPDF, targetRef } = usePDF({
    filename: `${rxData?.invoice_id}.pdf` || "Invoice.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    // Hide no-print elements before generating the PDF
    noPrintElements.forEach((el) => (el.style.display = "none"));

    // Generate the PDF
    toPDF().then(() => {
      // Show no-print elements after PDF is generated
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };

 

  return (

      <PrintDrawer title={rxData?.patient_name} visible={visible} onClose={onClose}>
      <div className="invoices-print">

        <div className="invoice-container">
        <Button onClick={downloadPDF} className="mb-2" type="primary">
            Download PDF
          </Button>
          <Card className="invoice-content" ref={targetRef}>
          <InvoiceBar invoice={rxData}/>
            <InvoiceHeader
              officeInformation={officeSetting}
              patientInformation={patientBasicInfo}
              rxData={rxData}
              billingInformation={billingInformation}
            />
            <Card>
            <Table
              size="small"
              columns={coloumn}
              pagination={false}
              dataSource={rxData?.product_details}
            />
            </Card>
              <InvoiceFooter subTotal={rxData?.price} discount={rxData?.discount} discountAmount={rxData?.discounted_amount} tax={rxData?.tax} grandTotal={rxData?.total_price}/>
          </Card>
        </div>
      </div>
      </PrintDrawer>
  );
};

export default PrescriptionPad;
