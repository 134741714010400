import React, { useEffect, useState } from 'react'
import {  Button, Card,  Tag,  Tooltip} from 'antd'
import { CSVLink } from "react-csv";
import { doGet } from '../../../../API/apis';
import { useOutletContext } from 'react-router-dom';
import { formatDate, formatTime } from '../../../../utils/DateFormat';
import ViewInvoice from '../../PatientSummary/InvoiceAndBilling/Invoices/component/Service/components/ViewInvoice';
import { useStateGlobalContext } from '../../../../contexts/GlobalContext';
import { NumberFormatter } from '../../../../utils/validator';
import Notification from '../../../../components/Notification/Notification';
import FormatDateTime from '../../../../components/FormatDate/FormatDateTime';
import DateRangePickerCard from '../../../../components/GlobalComponents/DateRangePickerCard';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { invoiceStatus } from '../../../../utils/Data';
import { calculateGrandTotalSum } from '../../../../utils/utils';
import ReportsCards from '../../../../components/GlobalComponents/ReportsCards';



const Services = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false)
  const [serviceData, setServiceData]=useState(null)
  const [services, setServices] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [officeDateFormat, officeTimeFormat, officeTimezone] = useOutletContext(); 
  const { profile } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  const showServiceInvoice= (record)=>{
    setVisible(true)
    setServiceData(record)
  }

  const onClose = ()=>{
    setVisible(false)
  }

  const handleReset = () => {
    setServices([]);
    setTotalRevenue(0)
  };

  const tranformServicesData =(records)=>{
    if (!records || !Array.isArray(records)) {
      return [];
    }
    return records.map((record) => ({
      // ID: record?.id,
      "Patient ID":record?.patient,
      "Invoice ID": record?.invoice_id,
      "Patient Name": record?.patient_name,
      "Service Name":record?.service_name,
      "Service Type": record?.service_type,
      "Service Group": record?.service_group,
      "Visit Reason": record?.visit_reason,
      "Price": record?.price,
      "Discount":record?.discount,
      "Tax": record?.tax,
      "Grand Total": record?.grand_total_amount,
      "Notes":record?.notes,
      "Status": record?.status,
      "Payment Method": record?.payment_method,
      "Issue Date": formatDate(record?.issue_date, officeDateFormat, officeTimezone),
      "Issue Time": formatTime(record?.issue_date, officeTimeFormat, officeTimezone),
      "Paid Date": record?.paid_on && formatDate(record?.paid_on, officeDateFormat, officeTimezone),
      "Paid Time": record?.paid_on && formatTime(record?.paid_on, officeTimeFormat, officeTimezone),
      "Created By":record?.created_by


    }));
  }

  const coloumn = [
    {
      key: 1,
      title:'Service Name',
      dataIndex: 'service_name',
      searchable:true,
      render: (text, record)=> <Button type='link' onClick={()=> showServiceInvoice(record)}>{text}</Button>
    },
    {
      key: 2,
      title:'Patient Name',
      dataIndex: 'patient_name',
      searchable:true,
    },
    {
      key: 3,
      title:'Service Type',
      dataIndex: 'service_type',
      width:"15%",
      ellipsis: true,
      searchable:true,
    },
    {
      key: 4,
      title:'Date',
      dataIndex: 'issue_date',
      align:'center',
      width:"10%",
      render : (text)=> (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
      )
    },
    {
      key: 5,
      title:'Time',
      dataIndex: 'issue_date',
      width:"8%",
      render : (text)=> (
      <Tooltip title={<FormatDateTime date={text} type="date" />}>
        <span>{text ? <FormatDateTime date={text} type="time" /> : ""}</span>
      </Tooltip>
      )
    },
    {
      key: 7,
      title: "Status",
      dataIndex: "status",
      width:"10%",
      ellipsis: true,
      align: "center",
      filters: invoiceStatus?.map((status) => ({
        text: status.label,
        value: status.label,
      })),
      onFilter: (value, record) =>
        record.status?.toLowerCase().includes(value.toLowerCase()),
      defaultFilteredValue: ["Paid", "unpaid"],
      render: (status) =>
        status === "Paid" ? (
          <Tag className="bg-[#1890ff]  text-white  rounded-md">
            {status}
          </Tag>
        ) : status === "unpaid" ? (
          <Tag className="bg-gray-400  text-white  rounded-md">
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Tag>
        ) : (
          <Tag className="bg-red-500 text-white  rounded-md">
            {status}
          </Tag>
        ),
    }, 
    {
      key: 6,
      title:'Amount',
      dataIndex: 'price',
      width:"10%",
      align:'center',
      render: (text) => <NumberFormatter value={text} options={{ style: "decimal" }} />
    },
  ]

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true); 
      getServices()
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getServices = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");
        const response = await doGet(`/reports/service-reports/?from_date=${startDate}&till_date=${endDate}`);
        setTotalRevenue(calculateGrandTotalSum(response?.data))
        if (response.status === 200) {
          setServices(response.data.reverse());
        }
      } else {
       Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(()=>{
    if(searchClicked){
      getServices()
      setSearchClicked(false)
    }
  },[searchClicked, selectedDateRange])

  return (
    <div>
      <h1 className="font-bold text-xl">Services Report </h1>
      <ReportsCards/>
      <Card className="shadow-class">
      <DateRangePickerCard
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onReset={handleReset}
        totalGrandTotal={totalRevenue || ""}
      />
      <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
         {role !=='nurse '&& role !=='doctor' && services?.length > 0 && <Button className="mr-2"  type="primary">
          <CSVLink
            data={tranformServicesData(services)}
            filename={"Services_reports.csv"}
            className="csv-link"
          >
            Download Report
          </CSVLink>
        </Button>}
        </div>
      <CustomTable
        size="small"
        loading={loading}
        columns={coloumn}
        dataSource={services}
      />
      </Card> 
      <ViewInvoice visible={visible} onClose={onClose} rxData={serviceData}/>
    </div>
  )
}

export default Services