import React from "react";
import "./invoiceheader.css";
import FormatDateTime from "../FormatDate/FormatDateTime";
import ClinicInformation from "../PrescriptionsPad/ClinicInformation";
import { Status } from "../../utils/Data";
import LabelDisplay from "../LabelDisplay/LabelDisplay";
import { fullAddress, fullName } from "../GlobalComponents/GlobalComponents";


const InvoiceHeader = ({
  officeInformation,
  patientInformation,
  rxData,
  // billingInformation,
}) => {
  return (
    <div>
      {/* <p className="text-center">
        <span className="font-semibold">Invoice ID: </span>
        {rxData?.inhouseUID}
        {rxData?.invoice_id}{" "}
        <span className="font-semibold ml-4">Status: </span> <Status status={rxData?.status}/>
      </p> */}
      <div className="flex justify-between mt-4 invoice-header">
        <div className="w-[33%]">
          <p className="font-semibold">Facility Information</p>
          {ClinicInformation(officeInformation)}

        </div>
        <div className="w-[33%]">
          <p className="font-semibold">Customer Information</p>
          <LabelDisplay title="Full Name" description={fullName(patientInformation)}/> 
          <LabelDisplay title="Address" description={fullAddress(patientInformation)}/> 
          <LabelDisplay title="Phone" description={patientInformation?.patient_email}/> 
          <LabelDisplay title="Email" description={fullName(patientInformation)}/> 
          <LabelDisplay title="Payment Method" description={rxData?.payment_method}/> 
          <LabelDisplay title="Email" description={fullName(patientInformation)}/> 


          <p className="font-semibold">Created By:</p>
          <LabelDisplay title="Name User ID" description={`${rxData?.created_by || rxData?.added_by}`}/>
          <LabelDisplay title="Date" description={<FormatDateTime date={rxData?.issue_date || rxData?.date_added} type="date"/>}/>
          <LabelDisplay title="Time" description={<FormatDateTime date={rxData?.issue_date || rxData?.date_added} type="time"/>}/>

        </div>
      </div>
    </div>
  );
};

export default InvoiceHeader;
