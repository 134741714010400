import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import logo from '../../../assets/logo.png';
import React, { useEffect, useState } from 'react';
import { useStateGlobalContext } from '../../../contexts/GlobalContext';
import Notification from '../../../components/Notification/Notification';

const Forgot_Password = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false); // Added to manage the success state
  const { appLogoData, getAppLogo } = useStateGlobalContext();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/reset-password/`, values);
      console.log("HELLO", response);
      if (response?.status) {
        setSuccess(true); // Set success to true
        form.resetFields();
        Notification.success('Reset link sent successfully');
      }
    } catch (error) {
      Notification.error("Username doesn't exist");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAppLogo();
  }, []);

  if (success) {
    // Show confirmation message after reset link is sent successfully
    return (
<div className="h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 to-white relative">
  {/* Background Image or Pattern */}
  <div className="absolute inset-0 opacity-10 bg-cover bg-center" style={{ backgroundImage: `url('https://source.unsplash.com/featured/?abstract,blue')` }}></div>
  
  {/* Top-left Logo */}
  <div className="absolute top-6 left-6">
    <img src={appLogoData?.icon || logo} alt="Logo" className="w-[180px] opacity-90" />
  </div>
  
  {/* Centered Content */}
  <div className="text-center bg-white p-8 rounded-lg shadow-2xl max-w-lg w-full">

    
    <h1 className="text-3xl font-extrabold text-[#3484F0] mb-4">
      Check Your Email!
    </h1>
    
    <p className="text-gray-700 text-lg leading-6 mb-6">
      A reset link has been sent to your registered email address. Please check your inbox (and spam folder) and follow the instructions to reset your password.
    </p>
    
    <Button
      type="primary"
      className="w-full mt-2 bg-[#3484F0] hover:bg-[#2968d9] text-white font-medium py-3 px-6 rounded-lg shadow-md"
      size="large"
      onClick={() => navigate('/')}
    >
      Go to Login
    </Button>
  </div>
</div>

    );
  }

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="absolute top-6 left-6">
        <img src={appLogoData?.icon || logo} alt="Logo" className="w-[200px]" />
      </div>
      <div className="bg-white p-8 rounded shadow-md max-w-md w-full">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <h1 className="text-3xl leading-7 text-[#3484F0] font-extrabold mb-3">
            Reset your password
          </h1>
          <p className="text-sm leading-5 mb-2">
            Enter your username and we'll send you a link to reset your password.
          </p>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input placeholder="Enter username" size="large" />
          </Form.Item>

          <Button
            size="large"
            className="bg-[#3484F0] text-[#fff] w-full mb-2"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Send Reset Link
          </Button>

          <p className="text-center">
            Already have an account?{' '}
            <Link to="/" className="text-[#3484F0]">
              Login
            </Link>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default Forgot_Password;
