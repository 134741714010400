import { Tag, Tooltip } from "antd";
import FormatDateTime from "../FormatDate/FormatDateTime";

export const StatusBadge = ({ status, statusUpdatedOn }) => {
  let bgColor = "";
  let textColor = "";

  switch (status) {
    case "In Waiting Room":
      bgColor = "bg-blue-400";
      textColor = "text-white";
      break;
    case "In Exam Room":
      bgColor = "bg-blue-700";
      textColor = "text-white";
      break;
    case "Ready for Provider":
      bgColor = "bg-green-400";
      textColor = "text-white";
      break;
    case "With Provider":
      bgColor = "bg-green-600";
      textColor = "text-white";
      break;
    case "Ready for Lab Work":
      bgColor = "bg-red-400";
      textColor = "text-white";
      break;
    case "Ready for Imaging":
      bgColor = "bg-yellow-400";
      textColor = "text-white";
      break;
    case "Ready for Check Out":
      bgColor = "bg-gray-400";
      textColor = "text-white";
      break;
    case "Checked Out":
      bgColor = "bg-gray-200";
      textColor = "text-gray-500";
      break;
    default:
      bgColor = "bg-gray-200";
      textColor = "text-black";
  }

  return (
    <Tag className={`${bgColor} ${textColor} rounded-md`}>
      <Tooltip
        title={
          <span>
            Last Status Updated:{" "}
            <FormatDateTime date={statusUpdatedOn} type="datetime" />
          </span>
        }
      >
        <span>{status}</span>
      </Tooltip>
    </Tag>
  );
};

export const InvoiceStatus = ({ status }) => {
  let bgColor = "";

  switch (status) {
    case "Paid":
    case "paid":
    case "Signed":
    case "signed":
    case "Dispensed":
    case "Sent":
      bgColor = "bg-[#1890ff]";
      break;
    case "Unpaid":
    case "unpaid":
    case "Draft":
    case "Not Sent":
      bgColor = "bg-gray-400";
      break;
    case "Cancelled":
    case "cancelled":
      bgColor = "bg-red-500";
      break;
    case "New":
      bgColor = "bg-green-600";
      break;
    default:
      bgColor = "bg-gray-400";
  }

  return <Tag className={`${bgColor} text-white rounded-md`}>{status}</Tag>;
};

export const AppointmentStatus = ({ status }) => {
  let bgColor = "";
  let textColor = "";
  switch (status) {
    case "Scheduled":
      bgColor = "bg-blue-200";
      textColor = "text-blue-500";
      break;
    case "Confirmed":
      bgColor = "bg-green-200";
      textColor = "text-green-500";
      break;
    case "Rescheduled":
      bgColor = "bg-blue-200";
      textColor = "text-blue-500";
      break;
    case "Late Arrival":
      bgColor = "bg-yellow-200";
      textColor = "text-yellow-500";
      break;
    case "No Show":
      bgColor = "bg-red-200";
      textColor = "text-red-500";
      break;
    case "Cancelled":
      bgColor = "bg-red-200";
      textColor = "text-red-500";
      break;
    default:
      bgColor = "bg-green-700";
      textColor = "text-white";
      break;
  }

  return (
    <Tag className={`${bgColor} ${textColor} rounded-md font-semibold`}>
      {status}
    </Tag>
  );
};

export const InsuranceClaimStatus = ({ status }) => {
  const statusStyles = {
    Draft: { bgColor: "bg-blue-400", textColor: "text-white" },
    Submitted: { bgColor: "bg-blue-700", textColor: "text-white" },
    "on Hold": { bgColor: "bg-yellow-400", textColor: "text-white" },
    Denied: { bgColor: "bg-orange-700", textColor: "text-white" },
    Approved: { bgColor: "bg-green-400", textColor: "text-white" },
    Paid: { bgColor: "bg-green-700", textColor: "text-white" },
    "Ready for Check Out": { bgColor: "bg-gray-400", textColor: "text-white" },
    "Checked Out": { bgColor: "bg-gray-200", textColor: "text-gray-500" },
  };

  const { bgColor, textColor } = statusStyles[status] || {
    bgColor: "bg-gray-200",
    textColor: "text-white",
  };

  return (
    <Tag className={`${bgColor} ${textColor} rounded-md font-semibold`}>
      {status}
    </Tag>
  );
};
