import { useState, useEffect, useContext } from "react";
import {
  SettingOutlined,
  HomeOutlined,
  CalendarOutlined,
  FolderOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { LuFileCheck } from "react-icons/lu";
import {FaTasks} from 'react-icons/fa'
import {MdOutlineMedicalServices , MdOutlineCardMembership} from 'react-icons/md'
import {LiaClinicMedicalSolid} from 'react-icons/lia'
import { Layout, Menu, theme , Spin} from "antd";
import logo from "../../assets/logo.png";
import logo1 from "../../assets/star.png";
import AppHeader from "../../components/Header/Header";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import "./layout.css";
import { LuSofa } from "react-icons/lu"; 
import { CgTemplate } from "react-icons/cg"; 
import { TbReportSearch } from "react-icons/tb"; 
import { MdOutlineHomeRepairService } from "react-icons/md";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import ThemeContext from "../../contexts/ThemeContext";

const { Header, Sider, Content } = Layout;
const ApplicationLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { theme: appTheme } = useContext(ThemeContext)
  const [isLoading, setIsLoading] = useState(true);
  const { officeSetting, getOfficeSettings, appLogoData , getAppLogo, profile , getProfile} = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  useEffect(() => {
    const fetchData = async () => {
      await getAppLogo(); // Fetch logo data
      await getOfficeSettings(); // Fetch office settings
      await getProfile()

      // Data fetched, remove loading state
      setIsLoading(false); 
    };

    fetchData(); 
  }, []); 

  const officeDateFormat = officeSetting && officeSetting?.date_format
  const officeTimeFormat = officeSetting && officeSetting?.time_format
  const officeTimezone = officeSetting && officeSetting?.facility_time_zone 


  const menuItems = [
    {
      key: "/main",
      icon: <HomeOutlined />,
      label: "Admin Dashboard",
      role:["clinic", "manager"]
    },

    {
      key: "/main/work_dashboard",
      icon: <LiaClinicMedicalSolid  size={18} />,
      label: "Work Dashboard",
      role:["clinic", "manager", "nurse", "doctor"]
    },
    {
      key: "/main/appointments",
      icon: <CalendarOutlined />,
      label: "Appointment",
      role:["clinic", "manager", "nurse", "doctor"]
    },
    {
      key: "/main/charts",
      icon: <ProfileOutlined />,
      label: "Charts",
      role:["clinic", "manager", "nurse", "doctor"]
    },
    {
      key: "/main/waiting-room",
      icon: <LuSofa size={18} />,
      label: "Waiting Room",
      role:["clinic", "manager", "nurse", "doctor"]
    },
    {
      key: "/main/tasks",
      icon: <FaTasks size={18} />,
      label: "Tasks",
      role:["clinic", "manager", "nurse", "doctor"]
    },

    {
      key: "/main/services",
      icon: <MdOutlineHomeRepairService size={18} />,
      label: "Services",
      role:["clinic", "manager", "doctor"]
    },
    {
      key: "/main/inventory",
      icon: <MdOutlineMedicalServices size={18}/>,
      label: "Inventory",
      role:["clinic", "manager", "nurse", "doctor"]
    },
    {
      key: "/main/memberships",
      icon: <MdOutlineCardMembership size={18}/>,
      label: "Memberships",
      role:["clinic", "manager", "nurse", "doctor"]
    },
    {
      key: "/main/templates",
      icon: <CgTemplate size={18} />,
      label: "Templates",
      role:["clinic", "manager", "doctor"]
    },
    {
      key: "/main/reports",
      icon: <TbReportSearch size={18} />,
      label: "Reports",
      role:["clinic", "manager", "doctor"]
    },
    {
      key: "/main/setting",
      icon: <SettingOutlined />,
      label: "Office Setting",
      role:["clinic", "manager"]
    },

  ];

  // React hooks
  const location = useLocation();
  const navigate = useNavigate();

  // Theme
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const filteredMenuItems = menuItems.filter(item => item.role.includes(role));


  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className="h-screen" style={{ background: appTheme === 'dark' ? "#fff9d8" : colorBgContainer }}>
      {
        isLoading ? (
          <div className="flex items-center justify-center h-screen">
          <Spin size="large" />
        </div>
        ): (
          <>
      <Sider
        trigger={null}
        theme={appTheme}
        width={256}
        style={{
          background: appTheme ==='dark' ? "#001529" : "#f7f7f7",
          height: "100vh",
          position: "fixed",
          borderRight: "1px solid #f0f0f0",
          transition: "width 0.3s",
          zIndex: 1,
        }}
        collapsible
        collapsed={collapsed}
        onCollapse={toggleSidebar}
        className="lg:fixed lg:left-0 lg:top-0 lg:h-screen"
      >
        <div
          className="flex justify-center brand"
          style={{
            transition: "margin-left 0.2s",
          }}
        >
          {collapsed ? (
            <img src={logo1} alt="Logo" className="w-[2rem] my-5 logo" />
          ) : (
            <img src={appLogoData?.icon || logo} alt="Logo" className="w-[10rem] my-5" />
          )}
        </div>
        <Menu
          theme={appTheme}
          style={{
            background: appTheme ==='dark' ? "#001529" : "#f7f7f7",
            marginTop: "1rem",
          }}
          mode="inline"
          defaultSelectedKeys={[""]}
          selectedKeys={[location.pathname]}
          onClick={({ key }) => navigate(key)}
        >

          {filteredMenuItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
        <Header
          
          style={{
            padding: 0,
            background: appTheme ==='dark' ? "#fff9d8" : colorBgContainer,
            width: collapsed ? "calc(100% - 80px)" : "calc(100% - 256px)",
            position: "fixed",
            minWidth: collapsed ? "calc(100% - 80px)" : "calc(100% - 256px)",
            marginLeft: collapsed ? "80px" : "256px",
            zIndex: 29,
            transition: "margin-left 0.2s",
          }}
          className="header-class lg:ml-80px lg:fixed lg:top-0 lg:w-[calc(100%-80px)] shadow-sm"
        >
          <AppHeader collapsed={collapsed} toggleSidebar={toggleSidebar} /> 
        </Header>
        <Content
          style={{
            padding: 24,
            // minHeight: '100vh',
            marginLeft: collapsed ? "80px" : "256px",
            background: appTheme === 'dark' ? "#fff9d8" : colorBgContainer,
            marginTop: "64px",
          }}
          className="lg:ml-80px mt-[80px]"
        >
          <Outlet context={[officeDateFormat, officeTimeFormat, officeTimezone]}/>
        </Content>
        </>
        )}
      </Layout>
  );
};

export default ApplicationLayout;
