import { Tag } from "antd";

export const taskActions = [
  {
    key: 1,
    label: "Edit",
  },
  {
    key: 2,
    label: "To Do",
  },
  {
    key: 3,
    label: "In Progress",
  },
  {
    key: 4,
    label: "Need Help!",
  },
  {
    key: 5,
    label: "High Priority",
  },
  {
    key: 6,
    label: "Mark as Completed",
  },
];

export const actionItems = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Edit",
  },
  {
    key: 3,
    label: "Delete",
  },
];

export const encounter_Status = [
  { key: 1, label: "Draft" },
  { key: 2, label: "Signed" },
  { key: 3, label: "Cancelled" },
];

export const referral_Status = [
  { key: 1, label: "Signed" },
  { key: 2, label: "Cancelled" },
];

export const actions = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Print",
  },
  {
    key: 3,
    label: "Cancel",
  },
];

export const visits_actions = [
  { key: 1, label: "View" },
  { key: 2, label: "Edit" },
  { key: 3, label: "Print" },
  { key: 5, label: "Delete" },
  { key: 6, label: "Cancel Encounter" },
];

export const invoiceStatus = [
  { key: 1, label: "Paid" },
  { key: 2, label: "unpaid" },
  { key: 3, label: "Cancelled" },
];

export const invoiceActions = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Collect Payment",
  },
  {
    key: 3,
    label: "Print",
  },
  {
    key: 4,
    label: "Cancel Invoice",
  },
];

export const outstandingBillActions = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Edit",
  },
  {
    key: 3,
    label: "Mark as Paid",
  },
  {
    key: 4,
    label: "Delete",
  },
];

export const orderedLabsActions = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 4,
    label: "Print",
  },
  {
    key: 2,
    label: "Mark as Completed",
  },
  {
    key: 5,
    label: "Delete",
  },
];

export const todayAppointmentActions = [
  {
    key: 1,
    label: "Scheduled",
  },
  {
    key: 8,
    label: "Confirmed",
  },
  {
    key: 2,
    label: "Arrived",
  },
  {
    key: 3,
    label: "Rescheduled",
  },
  {
    key: 4,
    label: "Late Arrival",
  },
  {
    key: 5,
    label: "Cancelled",
  },
  {
    key: 6,
    label: "No Show",
  },
  {
    key: 7,
    label: "Delete",
  },
];

export const creditMemoActions = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Edit",
  },
  {
    key: 3,
    label: "Redeem",
  },
  {
    key: 4,
    label: "Delete",
  },
];

export const superbillActions = [
  { key: 1, label: "View" },
  { key: 2, label: "Mark as Send" },
  { key: 3, label: "Print" },
  { key: 4, label: "Delete" },
];

export const generalNotesItems = [
  { key: 1, label: "View" },
  { key: 2, label: "Edit" },
  { key: 3, label: "Print" },
  { key: 5, label: "Delete" },
  { key: 6, label: "Cancel General Notes" },
];

export const waitingRoomStatus = [
  {
    key: 1,
    label: "In Waiting Room",
  },
  {
    key: 2,
    label: "In Exam Room",
  },
  {
    key: 3,
    label: "Ready for Provider",
  },
  {
    key: 4,
    label: "With Provider",
  },
  {
    key: 5,
    label: "Ready for Lab Work",
  },
  {
    key: 6,
    label: "Ready for Imaging",
  },
  {
    key: 7,
    label: "Ready for Check Out",
  },
  {
    key: 8,
    label: "Checked Out",
  },
];

export const statusMap = {
  1: "In Waiting Room",
  2: "In Exam Room",
  3: "Ready for Provider",
  4: "With Provider",
  5: "Ready for Lab Work",
  6: "Ready for Imaging",
  7: "Ready for Check Out",
  8: "Checked Out",
};

export const taskStatusMap = {
  2: "To Do",
  3: "In Progress",
  4: "Need Help!",
  5: "High Priority",
  6: "Completed",
};

export const insuranceClaimsStatus = [
  {
    key: "Draft",
    label: "Draft",
  },
  { key: "Submitted", label: "Submitted" },
];


export const Status = ({status})=>{
  if(status === "Paid" || status === "paid"){
    return <Tag color="green"> Paid </Tag>
  } else if(status === "Unpaid" || status === "unpaid"){
    return <Tag color="gray"> Unpaid </Tag>
  } else if(status === "Cancelled"){
    return <Tag color="red"> Cancelled</Tag>
  }
}

export const LabResultsActions = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Edit",
  },
  {
    key:3,
    label: "Print"
  },
  {
    key: 4,
    label: "Delete",
  },
];