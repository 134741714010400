import React, {useState} from 'react'
import { Tabs } from 'antd'
import PatientList from './PatientReports/PatientList';
import PatientHealthRecord from './PatientReports/PatientHealthRecord';
import MostVisited from './PatientReports/MostVisited';
import Referral from './PatientReports/Referral';


const items = [
    { label: "Patient's List Report", key: "1", children: <PatientList/>},
    { label: "Patient Health Record", key: "2", children: <PatientHealthRecord/> },
    { label: "Most Visited Report", key: "3", children: <MostVisited/> },
    { label: "Referral From Report", key: "5", children: <Referral/> },
  ];

const PatientReports = () => {

    const [activeKey, setActiveKey] = useState("1");

    const handleTabChange = (key) => {
      setActiveKey(key);
    };

  return (
    <Tabs
    items={items}
    activeKey={activeKey}
    type="card"
    size="large"
    onTabClick={handleTabChange}
   />
  )
}

export default PatientReports