import "./App.css";
import Protected from "./components/Protected";
import { Route, Routes } from "react-router-dom";

//Public Rotues
import Login from "../src/pages/Public/Login/Login";
import ForgetPassword from "../src/pages/Public/ForgetPassword/ForgetPassword";
import VerifyOTP from "../src/pages/Public/VerifyOTP/VerifyOTP";

//Protected Routes
import Chart from "./pages/Dashboard/Charts";
import Services from "./pages/Dashboard/Services";

import Setting from "../src/pages/Dashboard/Setting/Setting";
import OfficeBasicSetting from './pages/Dashboard/Setting/components/Basic_information'
import OfficeBillingSetting from './pages/Dashboard/Setting/components/BillingInformation'
import RoomsAndDiscounts from './pages/Dashboard/Setting/components/RoomsAndDiscounts/index'
import OfficePaymentSetting from './pages/Dashboard/Setting/components/AccountPayment'
import UserMangement from './pages/Dashboard/Setting/components/UserManagement'

import Inventory from "./pages/Dashboard/Inventory";
import Tasks from "../src/pages/Dashboard/Tasks/Tasks";
import UserSetting from "./pages/Dashboard/UserSetting/UserSetting";
import Appointment from "./pages/Dashboard/Appointment";
import Memberships from "./pages/Dashboard/Membership";
import PatientSummary from "./pages/Dashboard/PatientSummary/PatientSummary";
import Dashboard from "../src/pages/Dashboard/AdminDashboard";
import AppLayout from "../src/components/ApplicationLayout/ApplicationLayout";
import RegisterPatient from "./pages/Dashboard/RegisterPatient/RegisterPatient";
import ForgotPasswordConfirm from "../src/pages/Public/ForgotPasswordConfirm/ForgotPassowrdConfirm";
import Summary from "./pages/Dashboard/PatientSummary/MedicalCards/Summary";
import GeneralNotes from "./pages/Dashboard/PatientSummary/GeneralNotes/GeneralNotes";
import Labs from "./pages/Dashboard/PatientSummary/Labs/Index";
import Prescription from "./pages/Dashboard/PatientSummary/Prescription/Prescription";

import InvoiceAndBilling from "./pages/Dashboard/PatientSummary/InvoiceAndBilling";
import Invoices from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/Invoices/index";
import ProductInvoices from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/Invoices/component/Product/ProductInvoices";
import OutstandingBills from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/OutstandingBills/OutstandingBills";
import CreditMemo from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/CreditMemo/CreditMemo";
import InsuranceClaim from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/InsuranceClaims/InsuranceClaims";
import Superbill from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/Superbill/Superbill";
import PaymentMethod from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/PaymentMethod/PaymentMethod";

import PatientProfile from "./pages/Dashboard/PatientSummary/PatientProfile/PatientProfile";
// import PatientBasicInformation from './pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/BasicInformation';

import PatientBasicInformation from "./pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/BasicInformation";
import ContactInformation from "./pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/ContactInformation";
import EmergencyContact from "./pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/EmergencyContact";
import Address from "./pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/Address";
import Demographics from "./pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/Demographics";
import Referral from "./pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/Referral";
import IdentficationCard from "./pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/IdentficationCard";
import RegistrationPictures from "./pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/Pictures";

//Templates
import Templates from "./pages/Dashboard/Templates/index";
import NotesTemplates from "./pages/Dashboard/Templates/CreateTemplates";

import PatientPortal from "./pages/Dashboard/PatientSummary/PatientPortal";
import PatientFiles from "./pages/Dashboard/PatientSummary/PatientFiles/PatientFiles";
import Visits from "./pages/Dashboard/PatientSummary/Visits/Visits";

//Reports
import Reports from "./pages/Dashboard/Reports";
import ServicesReports from "./pages/Dashboard/Reports/components/Services";
import LabsReports from "./pages/Dashboard/Reports/components/labs";
import InventoryReports from "./pages/Dashboard/Reports/components/ProductReports/InventoryReports";
import InsuranceClaims from "./pages/Dashboard/Reports/components/InsuranceClaims";
import CreditMemoReports from "./pages/Dashboard/Reports/components/CreditMemoReports";
import OutstandingBillsReports from "./pages/Dashboard/Reports/components/OutstandingBillsReports";
// import MostVisited from './pages/Dashboard/Reports/components/MostVisited'
// import MostRevenue from './pages/Dashboard/Reports/components/MostRevenue'
import ReferralReports from "./pages/Dashboard/Reports/components/Referral";
import AppointmentReports from "./pages/Dashboard/Reports/components/AppointmentReports";

import ClinicDashboard from "./pages/Dashboard/ClinicDashboard";
import WaitingRoom from "./pages/Dashboard/WaitingRoom/WaitingRoom";
import InventoryDashboard from "./pages/Dashboard/Inventory/InventoryDashboard";
import InventoryList from "./pages/Dashboard/Inventory/InventoryList";
import GuestCheckoutDrawer from "./pages/Dashboard/Inventory/GuestCheckout/GuestCheckoutReports";
import PatientReports from "./pages/Dashboard/Reports/components/PatientReports";
import PatientMembership from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/PatientMembership/PatientMembership";
import PaymentPreference from "./pages/Dashboard/PatientSummary/PatientProfile/components/PatientProfileInformation/PaymentPreference";
import Insurance from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/Insurance/index";
import Referal from "./pages/Dashboard/PatientSummary/Referal/Referal";
import ReferralDoctor from "./pages/Dashboard/Templates/ReferralDoctor";
import AppointmentCalendar from "./pages/Dashboard/Appointment/components/AppointmentCalender";
import AppointmentList from "./pages/Dashboard/Appointment/components/AppointmentList";
import SuperbillReports from "./pages/Dashboard/Reports/components/SuperbillReports";
import TomorrowAppointmentList from "./pages/Dashboard/Appointment/components/TomorrowAppointmentList";
import AuditReports from "./pages/Dashboard/Reports/components/Audit/AuditReports";
import ServiceInvoices from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/Invoices/component/Service/Invoices";
import LabInvoices from "./pages/Dashboard/PatientSummary/InvoiceAndBilling/Invoices/component/Lab/LabInvoices";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/verify-otp/:id" element={<VerifyOTP />} />
        <Route path="/" element={<Login />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route
          path="/user/reset-password/confirm/:username/:token"
          element={<ForgotPasswordConfirm />}
        />
        <Route path="main" element={<Protected Component={AppLayout} />}>
          <Route path="" element={<Dashboard />} />
          <Route path="work_dashboard" element={<ClinicDashboard />} />
          <Route path="services" element={<Services />} />
          <Route path="inventory" element={<Inventory />}>
            <Route index element={<InventoryDashboard />} />
            <Route path="inventory-list" element={<InventoryList />} />
            <Route
              path="guest-checkout-reports"
              element={<GuestCheckoutDrawer />}
            />
          </Route>
          <Route path="tasks" element={<Tasks />} />
          <Route path="waiting-room" element={<WaitingRoom />} />

          <Route path="templates" element={<Templates />}>
            <Route index element={<NotesTemplates />} />
            <Route path="referring-providers" element={<ReferralDoctor />} />
          </Route>

          <Route path="memberships" element={<Memberships />} />
          <Route path="profile" element={<UserSetting />} />

          <Route path="appointments" element={<Appointment />}>
            <Route index element={<AppointmentCalendar/>}/>
            <Route path='today-appointments' element={<AppointmentList/>}/>
            <Route path='tomorrows-appointments' element={<TomorrowAppointmentList/>}/>
          </Route>
          <Route path="charts" element={<Chart />} />
          <Route path="charts/patient/:id" element={<PatientSummary />}>
            <Route index element={<Summary />} />
            <Route path="visits" element={<Visits />} />
            <Route path="general-notes" element={<GeneralNotes />} />
            <Route path="medications" element={<Prescription />} />
            <Route path="labs&imaging" element={<Labs />} />
            <Route path="referral" element={<Referal />} />
            <Route path="invoice&billing" element={<InvoiceAndBilling />}>
              <Route index path="service-invoices" element={<ServiceInvoices />} />
              <Route index path="lab-invoices" element={<LabInvoices />} />
              <Route path="product-bills" element={<ProductInvoices />} />
              <Route path="outstanding-bills" element={<OutstandingBills />} />
              <Route path="credit-memo" element={<CreditMemo />} />
              <Route path="insurance-claims" element={<InsuranceClaim />} />
              <Route path="superbill" element={<Superbill />} />
              <Route path="payment-method" element={<PaymentMethod />} />
            </Route>
            <Route path="memberships" element={<PatientMembership />} />
            <Route path="patient-portal" element={<PatientPortal />} />
            <Route path="patient-files" element={<PatientFiles />} />
            <Route path="insurances" element={<Insurance />} />
            <Route path="profile" element={<PatientProfile />}>
              <Route index element={<PatientBasicInformation />} />
              <Route
                path="basic-information"
                element={<PatientBasicInformation />}
              />
              <Route
                path="contact-information"
                element={<ContactInformation />}
              />
              <Route path="emergency-contact" element={<EmergencyContact />} />
              <Route path="address" element={<Address />} />
              <Route path="demographics" element={<Demographics />} />
              <Route path="referral" element={<Referral />} />
              <Route
                path="identification-cards"
                element={<IdentficationCard />}
              />
              <Route
                path="upload-pictures"
                element={<RegistrationPictures />}
              />
              <Route
                path="payment-services"
                element={<PaymentPreference />}
              />
            </Route>
          </Route>
          <Route path="register_patient" element={<RegisterPatient />} />
          <Route path="setting" element={<Setting />}>
              <Route index element={<OfficeBasicSetting/>}/>
              <Route path="billing_information" element={<OfficeBillingSetting/>}/>
              <Route path="rooms_discounts" element={<RoomsAndDiscounts/>}/>
              <Route path="account_payment_status" element={<OfficePaymentSetting/>}/>
              <Route path="user_management" element={<UserMangement/>}/>

          </Route>
          <Route path="reports" element={<Reports />}>
            <Route index element={<PatientReports />} />
            <Route path="patient" element={<PatientReports />} />
            <Route path="appointment-report" element={<AppointmentReports />} />
            <Route path="services" element={<ServicesReports />} />
            <Route path="labs" element={<LabsReports />} />
            <Route path="inventory" element={<InventoryReports />} />
            <Route path="insurance-claims" element={<InsuranceClaims />} />
            <Route path="credit-memo" element={<CreditMemoReports />} />
            <Route
              path="outstanding-bills"
              element={<OutstandingBillsReports />}
            />
            <Route path="super-bill" element={<SuperbillReports />} />
            <Route path="referral" element={<ReferralReports />} />
            <Route path="audit-reports" element={<AuditReports />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
