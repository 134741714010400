import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { DateTime } from "luxon";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "./AppoinmentCalender.css";
import { doGet, doPost, doPatch, doDelete } from "../../../../API/apis";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { useOutletContext } from "react-router-dom";
import BaseDatePicker from "../../../../components/BaseDatePicker/BaseDatePicker";
import luxonPlugin from "@fullcalendar/luxon3";
import {
  Button,
  Modal,
  Input,
  Form,
  Row,
  Col,
  Select,
  TimePicker,
  Avatar,
  Checkbox,
  Tooltip,
  AutoComplete,
} from "antd";
import TimeClock from "../../../../components/TimeClock/TimeClock";
import moment from "moment-timezone";
import Notification from "../../../../components/Notification/Notification";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import { useAppointmentSearchServices, useSearchPatient } from "../../../../API/apiUtils";
import FormatDate from "../../../../components/FormatDate/FormatDate";
import AppointmentDatePicker from "../../../../components/BaseDatePicker/AppointmentDatePicker";
import { phoneValidator } from "../../../../utils/validator";
const format = "h:mm a";
const { Option } = Select;

const SUCCESS_MESSAGES = {
  update: "Appointment Updated Successfully",
  add: "Appointment Created Successfully",
};

function AppointmentCalendar() {
  const [form] = Form.useForm();
  const calendarRef = useRef(null);
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(true);
  const [isVisible, setVisible] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [patientName, setPatientName] = useState(null);
  const [clientDetails, setClientDetails] = useState([]);
  const [appointmentWith, setAppointmentWith] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentTime, setAppointmentTime] = useState(null);
  const [isNotRegistered, setIsNotRegistered] = useState(false);
  const [selectedProvider, setSelectedDProvider] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const { options, handleSearchServices } = useAppointmentSearchServices();
  const {options: nameOptions, handleSearchPatient} = useSearchPatient(({ fullName }) => fullName);
  const [officeDateFormat, officeTimeFormat, officeTimezone] = 
    useOutletContext();
  const [currentView, setCurrentView] = useState("dayGridMonth");

  const { officeSetting, providers } = useStateGlobalContext();
  const operatingHours = officeSetting && officeSetting?.operating_hrs[0];
  const CalenderOpeningTime = operatingHours?.start_time;
  const CalenderClosingTime = operatingHours?.end_time;

  const disabledDate = (current) => current && current > new Date();

  const handleProviderSearch = (value) => {
    const filteredSuggestions =
      providers &&
      providers?.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(value.toLowerCase())
      );
    setSuggestions(filteredSuggestions);
  };

  const handleSelect = (value, option) => {
    const selectedProviderID = option.key;
    const selectedProviderName = option.children;
    setSelectedDProvider({
      id: selectedProviderID,
      name: selectedProviderName,
    });
  };

  //Patient Not registered

  const notRegistered = (e) => {
    const notRegistered = e.target.checked;
    setIsNotRegistered(notRegistered);
  };

  const handleDateClick = (selected) => {
    if (selected) {
      const date = selected.date;
      const selectedTime = DateTime?.fromJSDate(date, { zone: officeTimezone });
      const formattedDate = selectedTime.toFormat("MM-dd-yyyy");
      const formattedTime = selectedTime.toFormat("HH:mm a");
      // console.log(formattedDate, formattedTime);
      setAppointmentDate(formattedDate);
      setAppointmentTime(formattedTime);
      setVisible(true);
      form.setFieldsValue({
        start_time: dayjs(formattedTime, format),
        appointment_date: dayjs(formattedDate),
      });
    } else {
      console.error("Invalid selected date object:", selected);
    }
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    if (
      "appointment_date" in changedValues ||
      "start_time" in changedValues ||
      "duration" in changedValues ||
      "appointment_with" in changedValues
    ) {
      const selectedDate = allValues["appointment_date"];
      const provider = allValues["appointment_with"];
      const selectedStartTime =
        allValues["start_time"] || dayjs().startOf("day");
      const selectedDuration = allValues["duration"] || 15;

      const formattedDate = dayjs(selectedDate).format("MM-DD-YYYY");
      const formattedStartTime = selectedStartTime.format("h:mm a");
      const formattedEndTime = selectedStartTime
        .add(selectedDuration, "minutes")
        .format("h:mm a");

      setStartTime(formattedStartTime);
      setAppointmentDate(formattedDate);
      setAppointmentWith(provider);
      setAppointmentTime(`${formattedStartTime} - ${formattedEndTime}`);
    }
  };

  const handleEventClick = (selected) => {
    setEdit(true);
    setSelectedAppointment(selected?.event?._def?.publicId);

    const { start, end } = selected.event;
    const {
      service_name,
      appointment_with,
      reason_for_visit,
      first_name,
      last_name,
      middle_name,
      patient_id,
      contact,
      dob,
    } = selected?.event?.extendedProps;

    const formattedDate = moment(start).tz(officeTimezone);
    const formattedStartTime = moment(start)
      .tz(officeTimezone)
      .format("h:mm a");
    const patient_name =
      first_name && last_name ? `${first_name} ${middle_name} ${last_name}` : "";

    setAppointmentDate(formattedDate);
    setAppointmentTime(formattedStartTime);
    setAppointmentWith(appointment_with);
    setPatientName(patient_name);

    if (!patient_id) {
      form.setFieldsValue({
        unregistered_patient: true,
        first_name: first_name,
        last_name: last_name,
        appointment_date: dayjs(formattedDate),
        start_time: dayjs(formattedStartTime, format),
        duration: moment(end).diff(moment(start), "minutes"),
        service: service_name,
        appointment_with: appointment_with,
        reason_for_visit: reason_for_visit || "",
        phone: contact,
        dob: dob,
      });
      setIsNotRegistered(true);
      setVisible(true);
    } else {
      form.setFieldsValue({
        patient_name: patient_name,
        appointment_date: dayjs(formattedDate),
        start_time: dayjs(formattedStartTime, format),
        duration: moment(end).diff(moment(start), "minutes"),
        service: service_name,
        appointment_with: appointment_with,
        reason_for_visit: reason_for_visit || "",
      });
      setIsNotRegistered(false);
      setVisible(true);
    }
  };

  const events = data?.map((appointment) => {
    const {
      id,
      first_name,
      middle_name,
      last_name,
      dob,
      gender,
      clinic,
      contact,
      appointment_with,
      appointment_date,
      service,
      visit_reason,
      duration,
      patient_id,
    } = appointment;

    const endTime = dayjs(appointment_date);
    const newEndTime = endTime.add(duration, "minute");
    const newTimeString = newEndTime.toISOString();
    const fullName = `${first_name} ${middle_name} ${last_name}`;

    return {
      id,
      title: `${fullName} - Appointment with ${appointment_with}`,
      start: appointment_date,
      end: newTimeString,
      first_name,
      middle_name,
      last_name,
      service_name: service,
      appointment_with,
      reason_for_visit: visit_reason,
      dob,
      gender,
      contact,
      clinic,
      duration,
      patient_id,
    };
  });

  const handleCancel = () => {
    setVisible(false);
    setSelectedAppointment(null);
    setAppointmentDate(null);
    setAppointmentWith(null);
    setClientDetails(null);
    setIsNotRegistered(false);
    setTimeout(() => {
      setEdit(false);
      form.resetFields();
    }, 200);
  };

  //On Patient Select
  const onSelect = (value, option) => {
    const selectedClient = nameOptions.find((item) => item.value === value);

    if (selectedClient) {
      setClientDetails({
        patient_id: option?.id,
        first_name: option?.first_name,
        middle_name: option?.middle_name,
        last_name: option?.last_name,
        patient_MRN: option?.mrn_number,
        dob: option?.date_of_birth,
        contact: option?.patient_phone,
        gender: option?.gender,
        profile_picture: option?.profile_picture,
      });
    }
  };

  const patient_name =
    clientDetails?.first_name + " " + clientDetails?.middle_name + " " + clientDetails?.last_name;


  const handleAppoinmentSchedule = async (values) => {
    setSubmit(true)
    const {
      first_name,
      middle_name,
      last_name,
      phone,
      start_time,
      duration,
      service,
      reason_for_visit,
      dob,
    } = values;
    const apt_date = values?.appointment_date?.format("YYYY-MM-DD");
    const appointment_dateTime = moment
      .tz(`${apt_date} ${start_time.format("HH:mm")}`, officeTimezone)
      .utc()
      .format();
    const data = {
      patient_id: clientDetails?.patient_id,
      first_name: clientDetails?.first_name || first_name,
      middle_name: clientDetails?.middle_name || middle_name,
      last_name: clientDetails?.last_name || last_name,
      patient_MRN: clientDetails?.patient_MRN,
      gender: clientDetails?.gender,
      dob: clientDetails?.dob || dob,
      contact: clientDetails?.contact || phone,
      appointment_date: appointment_dateTime,
      time_zone_value: officeTimezone,
      time: start_time.format("HH:mm:ss"), // Use start_time directly
      duration: duration || 15,
      service: service,
      appointment_with: selectedProvider?.name,
      provider_id: selectedProvider?.id,
      visit_reason: reason_for_visit,
      current_status: edit ? "Rescheduled" : "Scheduled",
    };
    
    try {
      const response = edit
        ? await doPatch(`/appointments/update/${selectedAppointment}/`, data)
        : await doPost("/appointments/make/", data);
      if (response.status === 201 || response.status === 200) {
        Notification.success(
          edit ? SUCCESS_MESSAGES.update : SUCCESS_MESSAGES.add
        );
        setSubmit(false)
        getAppointments();
        handleCancel();
      } else if (response.status === 406) {
        Notification.warning("Provider not availble in this duration");
      } else if (response.status === 400) {
        Notification.error("Appointment Date can not be in the past");
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally{
      setSubmit(false)
    }
  };

  const handleDelete = (selectedAppointment) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteAppointment(selectedAppointment);
      },
    });
  };

  const deleteAppointment = async (record) => {
    try {
      const response = await doDelete(`/appointments/delete/${record}/`);
      if (response.status === 200) {
        Notification.success("Appointment deleted successfully");
        getAppointments();
        handleCancel();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAppointments = async () => {
    setLoading(true);
    try {
      if (currentView?.startStr) {
        // 1. Convert ISO String to Date Object
        const startDateObj = new Date(currentView?.startStr);
        const endDateObj = new Date(currentView?.endStr);

        // 2. Format Dates (e.g., YYYY-MM-DD)
        const startDate = startDateObj.toISOString().slice(0, 10); // YYYY-MM-DD
        const endDate = endDateObj.toISOString().slice(0, 10); // YYYY-MM-DD

        const response = await doGet(
          `/reports/appointments/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          setData(response?.data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAppointments();
  }, [currentView?.startStr, currentView?.endStr]);

  return (
    <div className="calender mb-5  mx-auto">
      <TimeClock />
      <div className="flex  justify-center mt-4"></div>
      <FullCalendar
        ref={calendarRef}
        height="70vh"
        timeZone={officeTimezone || "America/Central"}
        datesSet={(info) => setCurrentView(info)}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin,
          luxonPlugin,
        ]}
        nowIndicator={true}
        headerToolbar={{
          left: "prev,today,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        }}
        slotDuration="00:15:00"
        slotMinTime={CalenderOpeningTime || "08:00"}
        slotMaxTime={CalenderClosingTime || "18:00"}
        initialView="timeGridDay"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        events={events}
      />
      <Modal
        open={isVisible}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        onCancel={handleCancel}
        footer={null}
        width={680}
        maskClosable={false}
        className="appointment_modal"
      >
        <div className="appointment_box">
          <div className="left">
            <Avatar
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 80,
                xxl: 100,
              }}
              src={
                clientDetails?.profile_picture && clientDetails?.profile_picture
              }
            >
              {(!clientDetails?.profile_picture &&
                clientDetails?.first_name?.[0]) ||
                "A"}
            </Avatar>
            <p style={{ fontSize:'16px' }} className="text-white font-semibold">
              {clientDetails?.first_name
                ? patient_name
                : edit
                ? patientName
                : "Search Patient"}
            </p>
            <hr width="80%" />
            <p className="text-white" style={{fontSize:'16px'}}>Selected Date and Time</p>
            <p className="font-semibold" style={{fontSize:'16px'}}>
              <FormatDate date={appointmentDate} />
            </p>
            <p className="font-semibold" style={{fontSize:'16px'}}>{appointmentTime || "Appointment Time"}</p>
            <hr width="80%" />
            <p className="text-white" style={{fontSize:'16px'}}>Appointment With</p>
            <p className="text-white" style={{fontSize:'16px'}}> {appointmentWith || "Provider Name"}</p>
          </div>
          <Form
            layout="vertical"
            form={form}
            onValuesChange={handleFormValuesChange}
            onFinish={handleAppoinmentSchedule}
            className="appointment-form"
          >
            <div className="right-header">
              <p className="mb-4">
                {edit ? "Update Appointment" : "Book a New Appointment"}
              </p>
            </div>
            <Form.Item
              name="patient_name"
              label="Search Patient"
              tooltip="If No Patient Found Please Select New Unregistered Patient"
            >
              <AutoComplete
                showSearch
                options={nameOptions}
                allowClear={true}
                onSelect={onSelect}
                onSearch={handleSearchPatient}
                placeholder="Search for existing Patient"
                filterOption={false}
                notFoundContent={
                  !nameOptions.length ? "No matches found" : null
                }
              />
            </Form.Item>
            <Form.Item name="unregistered_patient" valuePropName="checked">
              <Checkbox onChange={notRegistered}>
                New Unregistered Patient or Client
                <Tooltip title="Check the box if the Patient or Client is not registered">
                  <QuestionCircleOutlined className="tooltip__icon" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
            {isNotRegistered && (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="first_name" label="First Name">
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="last_name" label="Last Name">
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item name="dob" label="DOB">
                      <BaseDatePicker disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="phone" label="Phone"
                    rules={[{ validator: phoneValidator }]}
                    >
                      <Input placeholder="Phone" maxLength={20} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <div className="right-header">
              <p className="mb-4">
                Appointment Details
              </p>
            </div>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="appointment_date"
                  label="Date"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Appointment Date",
                    },
                  ]}
                >
                  <AppointmentDatePicker value={appointmentDate} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="start_time"
                  label="Start Time"
                  rules={[
                    { required: true, message: "Start Time is Required" },
                  ]}
                >
                  <TimePicker use12Hours format="h:mm a" minuteStep={5} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="duration" label="Duration">
                  <Select placeholder="Duration" defaultValue="15">
                    <Option value="15"> 15 minutes</Option>
                    <Option value="30"> 30 minutes</Option>
                    <Option value="45"> 45 minutes</Option>
                    <Option value="60"> 60 minutes</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="service"
              label="Service Name"
              rules={[{ required: true, message: "Service is Required" }]}
            >
              <AutoComplete
                options={options}
                allowClear={true}
                width={"100%"}
                getItemValue={(item) => item.label}
                renderItem={(item, isHighlighted) => (
                  <div
                    key={item.id}
                    style={{
                      background: isHighlighted ? "lightgray" : "white",
                    }}
                  >
                    {item.label}
                  </div>
                )}
                // onSelect={onSelect}
                onSearch={handleSearchServices}
                placeholder="Search for service name"
              />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="appointment_with"
                  label="Provider Name"
                  rules={[{ required: true, message: "Provider is Required" }]}
                >
                  <Select
                    showSearch
                    placeholder="Search for providers"
                    style={{width:'394px'}}
                    onSearch={handleProviderSearch}
                    onSelect={handleSelect}
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    {suggestions?.map((doctor) => (
                      <Option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="reason_for_visit"
              label="Reason"
              rules={[
                { required: true, message: "Reason For Visit is Required" },
              ]}
            >
              <Input.TextArea
                placeholder="Reason for visit"
                maxLength={300}
                showCount
              />
            </Form.Item>
            <div className="flex justify-between mt-[1.4rem]">
              <Button onClick={handleCancel}>Cancel</Button>

              <div>
                {edit && (
                  <Button
                    className="danger__button mr-2"
                    onClick={() => handleDelete(selectedAppointment)}
                  >
                    Delete
                  </Button>
                )}
                <Button type="primary" htmlType="submit" loading={submit}>
                  {edit ? "Update" : "Book Appointment"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
export default AppointmentCalendar;
