import { notification } from 'antd';

// Configure notification placement
notification.config({
  placement: 'bottom', // Set placement to bottom
  // bottom: 20, // Distance from the bottom of the viewport
  duration: 5, // Default duration for notifications
});

const Notification = {
  success: (msg, description = '') => {
    notification.success({
      message: "Success",
      description: msg,
    });
  },
  error: (msg, description = '') => {
    notification.error({
      message: "Error",
      description: msg,
    });
  },
  info: (msg, description = '') => {
    notification.info({
      message: "Info",
      description: msg,
    });
  },
  warning: (msg, description = '') => {
    notification.warning({
      message: "Warning",
      description: msg,
    });
  },
};

export default Notification;
