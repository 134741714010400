import { Card, Button, Divider } from "antd";
import FaceSheetHeader from "../../../../../../../../components/FaceSheet/FaceSheetHeader";
import SingleInvoiceComponent from "./SingleInvoiceComponent";
import { usePDF } from "react-to-pdf";
import PrintDrawer from "../../../../../../../../components/GlobalComponents/PrintDrawer";

const SelectedInvoicesPrint = ({ visible, onClose, data }) => {



  const { toPDF, targetRef } = usePDF({
    filename: "invoice.pdf",
    page: {
      format: "A4",
      orientation: "portrait",
      margin: 10,
    },
    scale: 0.95,
  });

  const downloadPDF = () => {
    const noPrintElements = document.querySelectorAll(".no-print");

    noPrintElements.forEach((el) => (el.style.display = "none"));

    toPDF().then(() => {
      noPrintElements.forEach((el) => (el.style.display = ""));
    });
  };

  return (
    <PrintDrawer visible={visible} onClose={onClose}>
      <div className="prescription-pres-print max-w-[900px]">
        <div className="prescription-container">
          <div className="flex justify-end mb-2">
          <Button type="primary" onClick={downloadPDF}>
            Download PDF
          </Button>
          </div>
          <Card className="invoice-content" ref={targetRef}>
            <FaceSheetHeader />
            {data?.map((rxData, index) => (
              <>
                <Card className="flex flex-col mb-3" key={index}>
                  <SingleInvoiceComponent rxData={rxData} />
                </Card>
                <Divider />
              </>
            ))}
          </Card>
        </div>
      </div>
    </PrintDrawer>
  );
};

export default SelectedInvoicesPrint;
